import PRODUCT from './product'
import MERCHANT from './merchant/index'
import TENANT from './tenant/index'
import ISSUED from './issued'
import PLATFORM from './platform'
import BROKERAGE from '../sysapp/brokerage'
import ARTICLE from './article'
import RESOURCE from './resource'
import SETTING from './setting'
import TERMS from './merchant-terms'
import LINK from './link'
import SHARE from './merchant-share-ratio'

export default {
  PRODUCT,
  MERCHANT,
  TENANT,
  ISSUED,
  PLATFORM,
  BROKERAGE,
  ARTICLE,
  RESOURCE,
  SETTING,
  TERMS,
  LINK,
  SHARE
}
