/* eslint-disable */
export const gtmDebounce = (callbackFunc, wait, options = {}) => {
  let { immediate, trailing } = options
  var timeout, args, context, timestamp, result

  var later = function () {
    // 定时器设置的回调 later 方法的触发时间，和连续事件触发的最后一次时间戳的间隔
    // 如果间隔为 wait（或者刚好大于 wait），则触发事件
    var last = new Date().getTime() - timestamp // 距离最后一次执行方法的间隔时间

    // 时间间隔 last 在 [0, wait) 中
    // 还没到触发的点，则继续设置定时器
    // last 值应该不会小于 0 吧？
    if (last < wait && last >= 0) {
      // console.log('距离上次调用时间间隔(就是返回的闭包函数a最后的执行时间):', wait - last)
      timeout && clearTimeout(timeout)
      timeout = setTimeout(later, wait - last)
    } else {
      // 到了可以触发的时间点
      timeout = null
      // 可以触发了
      // 并且不是设置为立即触发的
      // 因为如果是立即触发（callNow），也会进入这个回调中
      // 主要是为了将 timeout 值置为空，使之不影响下次连续事件的触发
      // 如果不是立即执行，随即执行 func 方法
      if (!immediate) {
        // console.log('间隔到了', true)
        // 执行 func 函数
        result = callbackFunc.apply(context, args)
        // 这里的 timeout 一定是 null 了吧
        // 感觉这个判断多余了
        if (!timeout) context = args = null
      } else if (trailing) {
        // 拖尾执行
        // console.log('间隔到了---拖尾执行')
        // 执行 func 函数
        result = callbackFunc.apply(context, args)
        // 这里的 timeout 一定是 null 了吧
        // 感觉这个判断多余了
        if (!timeout) context = args = null
      }
    }
  }

  // 嗯，闭包返回的函数，是可以传入参数的
  // 也是 DOM 事件所触发的回调函数
  return function () {
    // 可以指定 this 指向
    context = this
    args = arguments
    console.log('参数', args)
    // 每次触发函数，更新时间戳
    // later 方法中取 last 值时用到该变量
    // 判断距离上次触发事件是否已经过了 wait seconds 了
    // 即我们需要距离最后一次事件触发 wait seconds 后触发这个回调方法
    timestamp = new Date().getTime()

    // 立即触发需要满足两个条件
    // immediate 参数为 true，并且 timeout 还没设置
    // immediate 参数为 true 是显而易见的
    // 如果去掉 !timeout 的条件，就会一直触发，而不是触发一次
    // 因为第一次触发后已经设置了 timeout，所以根据 timeout 是否为空可以判断是否是首次触发
    var callNow = immediate && !timeout

    // 设置 wait seconds 后触发 later 方法
    // 无论是否 callNow（如果是 callNow，也进入 later 方法，去 later 方法中判断是否执行相应回调函数）
    // 在某一段的连续触发中，只会在第一次触发时进入这个 if 分支中
    if (!timeout) {
      timeout = setTimeout(later, wait)
    } else {
      console.log('定时器已存在')
    }
    // 设置了 timeout，所以以后不会进入这个 if 分支了
    // 如果是立即触发
    if (callNow) {
      // func 可能是有返回值的
      result = callbackFunc.apply(context, args)
      // console.log('立即执行')
      // 解除引用
      if (!trailing) {
        context = args = null
      }
    }
    return result
  }
}

export const deepClone = (obj, cache = []) => {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }
  const hit = cache.filter(c => c.original === obj)[0]
  if (hit) {
    return hit.copy
  }

  const copy = Array.isArray(obj) ? [] : {}
  cache.push({
    original: obj,
    copy
  })
  Object.keys(obj)
    .forEach(key => {
      copy[key] = deepClone(obj[key], cache)
    })

  return copy
}

export function fileSizeFormat (size) {
  // 处理文件大小显示单位
  if (size === 'undefined' || /\D/.test(size)) {
    return 'N/A'
  }

  function round (num, precision) {
    return Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision)
  }

  let boundary = Math.pow(1024, 4)
  // TB
  if (size >= boundary) {
    return `${ round(size / boundary, 1) }T`
  }
  // GB
  if (size >= (boundary /= 1024)) {
    return `${ round(size / boundary, 1) }G`
  }
  // MB
  if (size >= (boundary /= 1024)) {
    return `${ round(size / boundary, 1) }M`
  }
  // KB
  if (size > 1024) {
    return `${ Math.round(size / 1024) }kb`
  }
  return `${ size }B`
}

// 判断的是否是JSON字符串
export const isJSON = str => {
  if (typeof str == 'string') {
    try {
      let obj = JSON.parse(str)
      // 等于这个条件说明就是JSON字符串 会返回true
      if (typeof obj == 'object' && obj) {
        return true
      } else {
        //不是就返回false
        return false
      }
    } catch (e) {
      return false
    }
  }
  return false
}

//时间格式化
export const dateFormat = (format, date) => {
  let ret
  const opt = {
    'Y+': date.getFullYear()
      .toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate()
      .toString(), // 日
    'H+': date.getHours()
      .toString(), // 时
    'M+': date.getMinutes()
      .toString(), // 分
    'S+': date.getSeconds()
      .toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (let k in opt) {
    ret = new RegExp('(' + k + ')').exec(format)
    if (ret) {
      format = format.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
    }
  }

  return format
}

export const getMimeTypeBySuffix = suffix => {
  let mimeType = ''
  switch (suffix.toLowerCase()) {
    case 'html':
      mimeType = 'text/html'
      break
    case 'txt':
      mimeType = 'text/plain'
      break
    case 'gif':
      mimeType = 'image/gif'
      break
    case 'jpeg':
      mimeType = 'image/jpeg'
      break
    case 'jpg':
      mimeType = 'image/jpeg'
      break
    case 'png':
      mimeType = 'image/png'
      break
    case 'mp4':
      mimeType = 'video/mp4'
      break
    case 'mpg':
      mimeType = 'video/mpeg'
      break
    case 'mpeg':
      mimeType = 'video/mpeg'
      break
    case 'avi':
      mimeType = 'video/x-msvideo'
      break
    case 'gz':
      mimeType = 'application/x-gzip'
      break
    case 'tar':
      mimeType = 'application/x-tar'
      break
    case 'doc':
      mimeType = 'application/msword'
      break
    case 'docx':
      mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      break
    case 'xls':
      mimeType = 'application/vnd.ms-excel'
      break
    case 'xlsx':
      mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      break
    case 'ppt':
      mimeType = 'application/vnd.ms-powerpoint'
      break
    case 'pptx':
      mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      break
    case 'gzip':
      mimeType = 'application/x-gzip'
      break
    case 'zip':
      mimeType = 'application/zip'
      break
    case '7zip':
      mimeType = 'application/zip'
      break
    case 'rar':
      mimeType = 'application/rar'
      break
    case 'pdf':
      mimeType = 'application/pdf'
      break
  }
  return mimeType
}

export const downloadFile = async (url, name) => {
  if (url) {
    const response = await fetch(url)
    // 检查是否成功获取资源
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${ response.status }`)
    }
    // 获取资源的Blob数据
    const blob = await response.blob()
    // 创建一个URL表示Blob对象
    const urlForBlob = window.URL.createObjectURL(blob)
    // 创建隐藏的a标签并触发下载
    const link = document.createElement('a')
    link.href = urlForBlob
    link.download = name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    // 释放URL对象
    window.URL.revokeObjectURL(urlForBlob)
  }
}

export const getOSAndVersion = () => {
  const userAgent = navigator.userAgent
  let os = ''
  let version = ''

  // 检测Windows
  if (/Windows NT (\d+\.\d+)/i.test(userAgent)) {
    os = 'Windows'
    version = RegExp.$1
  }
  // 检测Mac OS
  else if (/Mac OS X (\d+([._]\d+)+)/i.test(userAgent)) {
    os = 'Mac OS X'
    version = RegExp.$1.replace('_', '.')
      .replace('.', '.')
  }
  // 检测Linux，注意Linux版本通常不在User Agent中明确给出
  else if (/Linux/i.test(userAgent)) {
    os = 'Linux'
    version = 'Unknown' // 通常难以从User Agent准确获取Linux发行版和版本号
  }
  // 检测iOS
  else if (/CPU (?:iPhone|iPod|iPad) OS (\d+(_\d+)*)(?: like Mac OS X)/i.test(userAgent)) {
    os = 'iOS'
    version = RegExp.$1.replace('_', '.')
  }
  // 检测Android
  else if (/Android (\d+(\.\d+)+)/i.test(userAgent)) {
    os = 'Android'
    version = RegExp.$1
  } else {
    os = 'Unknown'
    version = 'Unknown'
  }

  return { os, version }
}

export const getBrowserInfoAndScreenResolution = () => {
  // 获取浏览器信息和版本
  const browserInfo = {
    name: '',
    version: ''
  }

  if (navigator.userAgent.indexOf("Chrome") > -1) {
    browserInfo.name = 'Chrome'
  } else if (navigator.userAgent.indexOf("Firefox") > -1) {
    browserInfo.name = 'Firefox'
  } else if (navigator.userAgent.indexOf("Safari") > -1) {
    browserInfo.name = 'Safari'
  } else if (navigator.userAgent.indexOf("Opera") > -1 || navigator.userAgent.indexOf("OPR") > -1) {
    browserInfo.name = 'Opera'
  } else if (navigator.userAgent.indexOf("MSIE") > -1 || navigator.userAgent.indexOf("Trident") > -1) {
    browserInfo.name = 'Internet Explorer'
  } else {
    browserInfo.name = 'Unknown'
  }

  // 获取浏览器版本（这里仅展示基于Chrome、Firefox等的简化版本提取方式）
  const matches = navigator.userAgent.match(/(Chrome|Firefox|Safari|OPR|Edge)\/?\s*(\d+(\.\d+)*)/)
  if (matches && matches.length >= 3) {
    browserInfo.version = matches[2]
  } else {
    browserInfo.version = 'Unknown Version'
  }

  // 获取屏幕分辨率
  const screenResolution = {
    width: window.screen.width,
    height: window.screen.height
  }

  return {
    browser: browserInfo,
    resolution: screenResolution
  }
}

//以英文分号进行分割（兼容中文分号）
export const stringToListBySemicolon = (str) => {
  // 将中文分号替换为英文分号
  let replacedString = str.replace(/；/g, ';')
  // 以英文分号进行分割，并去除空字符串
  return replacedString.split(';')
    .filter(Boolean)
}

