import axios from 'axios'
import { GUARANTEE_APP_PATH } from '../base-url'
export default {
  /**
   * 保存索赔信息
   * @param params
   * @returns {*} CLAIM.fetchUpdateClaimInfoSave
   */
  fetchUpdateClaimInfoSave (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/claim/save`, data, { params }).then(res => res.data)
  },
  /**
   * 提交索赔信息
   * @param params
   * @returns {*} CLAIM.fetchUpdateClaimInfoSubmit
   */
  fetchUpdateClaimInfoSubmit (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/claim/apply`, data, { params }).then(res => res.data)
  },
  /**
   * 查询索赔结果
   * @param params
   * @returns {*} CLAIM.fetchRequestClaimInfoByOrderNo
   */
  fetchRequestClaimInfoByOrderNo (params) {
    return axios.get(`${GUARANTEE_APP_PATH}/claim/result`, { params }).then(res => res.data)
  },
  /**
   * 重新索赔
   * @param params
   * @returns {*} CLAIM.fetchUpdateClaimInfoSubmitAgain
   */
  fetchUpdateClaimInfoSubmitAgain (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/claim/reApply`, data, { params }).then(res => res.data)
  }
}
