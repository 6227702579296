import axios from 'axios'
import { SYS_APP_PATH } from '../base-url'

export default {
  /**
   * 新增机构条款
   * @param params
   * @returns {*} $sysappApi.TERMS.fetchUpdateMerchantTerms
   */
  fetchUpdateMerchantTerms (params, data) {
    return axios.post(`${SYS_APP_PATH}/merchantTerms/edit`, data, { params }).then(res => res.data)
  },
  /**
   * 删除机构条款
   * @param params
   * @returns {*} $sysappApi.TERMS.fetchDeleteMerchantTerms
   */
  fetchDeleteMerchantTerms (params, data) {
    return axios.post(`${SYS_APP_PATH}/merchantTerms/delete`, data, { params }).then(res => res.data)
  }
}
