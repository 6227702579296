import axios from 'axios'
import { SYS_APP_PATH } from '../../base-url'

export default {
  /**
   * 查询文章列表
   * @param params
   * @returns {*} $sysappApi.ARTICLE.fetchGetArticleList
   */
  fetchGetArticleList (params, data) {
    return axios.post(`${SYS_APP_PATH}/article/list`, data, { params })
      .then(res => res.data)
  },
  /**
   * 保存文章详情
   * @param params
   * @returns {*} $sysappApi.ARTICLE.fetchSaveArticle
   */
  fetchSaveArticle (params, data) {
    return axios.post(`${SYS_APP_PATH}/article/save`, data, { params })
      .then(res => res.data)
  },
  /**
   * 查询文章详情
   * @param params
   * @returns {*} $sysappApi.ARTICLE.fetchGetArticleById
   */
  fetchGetArticleById (params, data) {
    return axios.post(`${SYS_APP_PATH}/article/find`, data, { params })
      .then(res => res.data)
  },
  /**
   * 上架文章
   * @param params
   * @returns {*} $sysappApi.ARTICLE.fetchUpdateArticleOnline
   */
  fetchUpdateArticleOnline (params, data) {
    return axios.post(`${SYS_APP_PATH}/article/online`, data, { params })
      .then(res => res.data)
  },
  /**
   * 发布文章
   * @param params
   * @returns {*} $sysappApi.ARTICLE.fetchUpdateArticlePublish
   */
  fetchUpdateArticlePublish (params, data) {
    return axios.post(`${SYS_APP_PATH}/article/publish`, data, { params })
      .then(res => res.data)
  },
  /**
   * 删除文章
   * @param params
   * @returns {*} $sysappApi.ARTICLE.fetchDeleteArticle
   */
  fetchDeleteArticle (params, data) {
    return axios.post(`${SYS_APP_PATH}/article/delete`, data, { params })
      .then(res => res.data)
  },
  /**
   * 获取租户上架文章
   * @param params
   * @returns {*} $sysappApi.ARTICLE.fetchGetArticleOnlineList
   */
  fetchGetArticleOnlineList (params, data) {
    return axios.post(`${SYS_APP_PATH}/article/onlineList`, data, { params })
      .then(res => res.data)
  }
}
