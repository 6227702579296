import axios from 'axios'
import { SYS_APP_PATH } from '../../base-url'

export default {
  /**
   * 获取帮助中心条目数据
   * @param params
   * @returns {any} $sysappApi.SETTING.fetchGetHelpItemList
   */
  fetchGetHelpItemList (params) {
    return axios.get(`${SYS_APP_PATH}/setting/help/item/list`, { params })
      .then(res => res.data)
  },
  /**
   * 保存帮助中心条目数据
   * @param params
   * @param data
   * @returns {any} $sysappApi.SETTING.fetchSaveHelpItem
   */
  fetchSaveHelpItem (params, data) {
    return axios.post(`${SYS_APP_PATH}/setting/help/item/save`, data, { params })
      .then(res => res.data)
  },
  /**
   * 删除帮助中心条目数据
   * @param params
   * @param data
   * @returns {any} $sysappApi.SETTING.fetchDeleteHelpItem
   */
  fetchDeleteHelpItem (params, data) {
    return axios.post(`${SYS_APP_PATH}/setting/help/item/delete`, data, { params })
      .then(res => res.data)
  }
}
