import COMMON from './common'
import USER from './user'
import COMMON_LOGIN from './common-login'
import PORTAL from './portal'
import MERCHANT from './merchant'
import ORDER from './order'
import INVOICE from './invoice'
import SURRENDER from './surrender'
import CLAIM from './claim'
import PAGE_FLOW from './page-flow'
import GUARANTEE_API from './guarantee-api'
import GUARANTEE_APPLY from './guarantee-apply'

export default {
  COMMON,
  USER,
  COMMON_LOGIN,
  PORTAL,
  MERCHANT,
  ORDER,
  INVOICE,
  SURRENDER,
  CLAIM,
  PAGE_FLOW,
  GUARANTEE_API,
  GUARANTEE_APPLY
}
