// 兼容ie11
import 'es6-promise/auto' // 自动 polyfill Promise
import 'whatwg-fetch'
import 'custom-event-polyfill'
import 'core-js/stable'
import 'core-js/web/url'
import 'core-js/stable/promise'
import 'core-js/stable/symbol'
import 'core-js/stable/string/starts-with'
import 'regenerator-runtime/runtime'
// 框架相关
import Vue from 'vue'
import App from './App.vue'
import store from './vuex'
import router from './router/router'

// UI库
import ElementUI from 'element-gui'
import '@gtm-components/element-gui-variables.scss'

import '@/assets/css/iconfont/iconfont.css'
import '@/assets/css/base.scss'
import '@/assets/css/gtm-base.scss'

// 内部依赖-GtmUtils工具库
import GtmUtils from 'web-utils'
import GtmButils from '@gtm-butils'
import '@gtm-components/assets/iconfont/iconfont.css'
import GtmApi from '@gtm-api'
import GtmComponents from '@gtm-components'
import initAxios from '@gtm-api/axios-config'
import { loadMicroApp } from 'qiankun'
import { startApp } from './start-app'

Vue.use(ElementUI)
Vue.use(GtmUtils)
Vue.use(GtmButils)
Vue.use(GtmApi)
Vue.use(GtmComponents)

Vue.config.productionTip = false
initAxios.initAxiosInterceptor()
Vue.prototype.$loadMicroApp = loadMicroApp

window.addEventListener('unhandledrejection', (event) => {
  console.error('Unhandled Rejection:', event.reason)
  // 根据需要处理错误
})

// 获取租户信息和全局配置后才渲染页面
startApp()
  .then(() => {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })
