<!--
  @name: 平台父应用布局
  @description:
  @author: 马群
  @time: 2023-11-24 16:01:47
-->
<template>
  <div class="gtm-base__page-layout">
    <GTMHeader
      v-if="showHeader"
      :show-menu="showHeaderMenu"
      :show-home-btn="showHeaderHome"
      :show-login-btn="showHeaderLogin"
      :is-context-loaded="microAppRender"
      :show-user-info="showUserInfo"
    >
    </GTMHeader>
    <div class="gtm-base__page-content" :class="!showHeader ? 'page-layout--full-page' : 'page-content--content-page'">
      <GTMAsideMenu v-if="showAside"></GTMAsideMenu>
      <div class="gtm-base__page-main" id="gtm-base__page-main">
        <router-view v-if="microAppRender"></router-view>
        <HomeSkeleton v-show="!microAppRender"></HomeSkeleton>
        <el-dialog
          :before-close="handleClose"
          :close-on-click-modal="false"
          :visible.sync="isLoginExpire"
          custom-class="dialog-LoginOut"
          title="提示"
          width="20%"
        >
          <div style="display: flex; align-items: center">
            <i class="el-icon-warning" style="font-size: 18px; color: #fc9306; margin-right: 5px"></i>
            <span> 登录已失效，请重新登录 </span>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleClose">{{ `我知道了 (${ closeTime }s)` }}</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// 平台头部菜单
import GTMHeader from './components/Header'
// 平台左侧菜单
import GTMAsideMenu from './components/AsideMenu'
import HomeSkeleton from './skeleton-page/HomeSkeleton'

export default {
  name: 'PlatformPage',
  components: { GTMHeader, GTMAsideMenu, HomeSkeleton },
  data () {
    return {
      microAppRender: false,
      isLogin: false,
      loginOuterTimer: false,
      closeTime: 3
    }
  },
  computed: {
    ...mapState([
      'rootFullPath',
      'isLoginExpire',
      'showHeader',
      'showHeaderMenu',
      'showHeaderHome',
      'showHeaderLogin',
      'showUserInfo',
      'showAside'
    ])
  },
  mounted () {
    console.log('【base-platform】【Platform.vue】执行mounted()方法')
  },
  watch: {
    $route: {
      handler: function (newValue, oldValue) {
        const currentFullPath = this.rootFullPath
        let queryNotEqual = newValue?.path === oldValue?.path && newValue.query !== oldValue?.query
        if ((currentFullPath && newValue?.path !== oldValue?.path) || queryNotEqual) {
          if (newValue?.name === oldValue?.name) {
            // 如果子应用没有变化的话，就别卸载了呗;
            if (queryNotEqual) {
              // 这里比较特殊，子应用自己跳自己，就直接刷新子应用
              this.initApp(true, 'reloadSelf')
            } else {
              this.initApp()
            }
          } else {
            // 如果子应用变化的话，那就得卸载了
            this.initApp(true)
          }
          let div = document.querySelector('#gtm-base__page-main')
          this.$nextTick(() => {
            if (div && div.scrollTop) {
              div.scrollTop = 0
            }
          })
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true
    },
    // 登录拦截
    isLoginExpire: {
      handler (val) {
        console.log('isLoginExpire', val)
        if (val) {
          this.loginOuterTimer = setInterval(() => {
            if (this.closeTime === 1) {
              clearInterval(this.loginOuterTimer)
              this.handleClose()
            } else {
              this.closeTime--
            }
          }, 1000)
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 调用公共上下文
    initApp (isReloadRouter = false, type) {
      // 路由名称不是这几个，才会加载上下文，不然加载也没意义
      const noContextRouteName = ['Register', 'Agreement', 'Privacy', 'Login', 'GtmLogin', 'Redirection']
      // 如果需要重新加载路由，那就得把这个变量重置一下，否则不必重置
      if (isReloadRouter) {
        this.microAppRender = false
      }
      // 没有上下文数据的时候，进入必定刷新一次
      const noRootData = !noContextRouteName.includes(this.$route.name) && this.$store.state.rootMicroAppData === null
      const needReloadPath = ['guarantee-web/projectList']
      const needReload = !noContextRouteName.includes(this.$route.name) && needReloadPath.includes(this.rootFullPath)
      if ((noRootData || needReload) && !this.$route?.query?.loginOut) {
        this.microAppRender = false
        this.$rootMethods.APP_CONTEXT.initAppContext()
          .finally(() => {
            console.log('【base-platform】【Platform.vue】子应上下文数据：', this.$rootState)
            this.$store.state.rootMicroAppData = this.$rootState
            console.log('【base-platform】【Platform.vue】上下文执行完成，渲染子应用')
            this.microAppRender = true
          })
      } else {
        if (!this.microAppRender) {
          if (type === 'reloadSelf') {
            setTimeout(() => {
              this.microAppRender = true
            }, 500)
          } else {
            this.microAppRender = true
          }
        }
      }
    },
    handleClose () {
      this.$store.state.isLoginExpire = false
      setTimeout(() => {
        this.closeTime = 3
        // 清除本地记录的用户信息
        let toRoutePath = ''
        let portalRootAppName = localStorage.getItem('portal_root_appName')
        let toLoginAppList = ['"backend"', '"development"', '"bi"']
        let originDomain = sessionStorage.getItem('TENANT_ORIGIN_DOMAIN')
        if (toLoginAppList.includes(portalRootAppName)) {
          toRoutePath = `${originDomain}/gtm/web/platform/login`
        } else {
          toRoutePath = `${originDomain}/gtm/web${this.$store.state.defaultMenuInfo.defaultPath}`
        }
        if (!window.location.origin.includes(originDomain)) {
          toRoutePath = `${toRoutePath}?loginOut=trade`
        }
        if (this.loginOuterTimer) {
          clearInterval(this.loginOuterTimer)
        }
        const portalRootUserLoginType = sessionStorage.getItem('portal_root_userLoginType')
        this.$rootMethods.TOOLS.clearLocalInfo()
        this.$store.state.rootMicroAppData = null
        this.$rootState.userInfo = {}
        this.$rootState.indexInfo = {
          code: 500,
          msg: '尚未登录，请先登录'
        }
        if (toLoginAppList.includes(portalRootAppName)) {
          window.location.href = toRoutePath
        } else {
          if (!portalRootUserLoginType || portalRootUserLoginType !== 'GTM_SAAS_SSO') {
            window.close()
            window.location.href = 'about:blank'
          } else {
            window.location.href = toRoutePath
          }
        }
      }, 500)
    }
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1280px) {
  .gtm-base__page-main {
    overflow-x: auto;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.gtm-base__page-layout {
  width: 100%;
  height: 100%;
  overflow: auto;
  min-width: 1440px;

  .gtm-base__page-content {
    overflow-y: auto;
    display: flex;
    flex-direction: row;
  }

  .page-layout--full-page {
    height: 100%;
    margin-top: 0;
  }

  .page-content--content-page {
    height: calc(100% - 60px);
    padding-top: 60px;
  }

  .gtm-base__page-main {
    flex: 1;
    overflow-y: auto;
    //overflow-x: hidden;
  }
}
</style>
