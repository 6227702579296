import axios from 'axios'
import { DEVELOPMENT_PATH } from '../../base-url'

export default {
  /**
   * 通过userId获取用户申请信息
   * @param userId
   * @param params
   * @returns {*} $developmentApi.INSTITUTION.fetchGetInstitutionApplyByUserId
   */
  fetchGetInstitutionApplyByUserId (userId, params) {
    return axios.get(`${DEVELOPMENT_PATH}/institutionapply/get/${userId}`, { params })
      .then(res => res.data)
  },
  /**
   * 通过userId获取用户所有申请信息
   * @param params
   * @param data
   * @returns {*} $developmentApi.INSTITUTION.fetchGetInstitutionApplyList
   */
  fetchGetInstitutionApplyList (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/institutionapply/list`, data, { params })
      .then(res => res.data)
  },
  /**
   * 提交申请
   * @param params
   * @param data
   * @returns {*} $developmentApi.INSTITUTION.fetchSaveInstitutionApply
   */
  fetchSaveInstitutionApply (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/institutionapply/submit`, data, { params })
      .then(res => res.data)
  },
  /**
   * 保存产品介绍提醒操作
   * @param params
   * @param data
   * @returns {*} $developmentApi.INSTITUTION.fetchAddInstitutionRemind
   */
  fetchAddInstitutionRemind (id) {
    return axios.post(`${DEVELOPMENT_PATH}/institutionapply/saveRemind/${id}`)
      .then(res => res.data)
  },
  /**
   * 提交申请
   * @param params
   * @param data
   * @returns {*} $developmentApi.INSTITUTION.fetchAddInstitutionApply
   */
  fetchAddInstitutionApply (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/institutionapply/submit`, data, { params })
      .then(res => res.data)
  }
}
