import axios from 'axios'
import { SYS_APP_PATH } from '../base-url'

export default {
  /**
   * 资源查询
   * @param params
   * @returns {*} $sysappApi.RESOURCE.fetchGetResourceList
   */
  fetchGetResourceList (params) {
    return axios.get(`${SYS_APP_PATH}/resource/list`, { params })
      .then(res => res.data)
  }
}
