import axios from 'axios'
import { SYS_APP_PATH } from '../base-url'

export default {
  /**
   * 获取分润合作机构列表
   * @param params
   * @returns {*} $sysappApi.SHARE.fetchGetPartnerMerchantShareList
   */
  fetchGetMerchantShareList (params, data) {
    return axios.post(`${SYS_APP_PATH}/merchantShareRatio/merchantShareList`, data, { params }).then(res => res.data)
  },
  /**
   * 获取分润合作机构配置列表
   * @param params
   * @returns {*} $sysappApi.SHARE.fetchGetPartnerMerchantShareConfigList
   */
  fetchGetPartnerMerchantShareConfigList (params, data) {
    return axios.post(`${SYS_APP_PATH}/merchantShareRatio/list`, data, { params }).then(res => res.data)
  },
  /**
   * 创建分润机构
   * @param params
   * @returns {*} $sysappApi.SHARE.fetchAddPartnerMerchant
   */
  fetchAddPartnerMerchant (params, data) {
    return axios.post(`${SYS_APP_PATH}/merchantShareRatio/saveMerchantShare`, data, { params }).then(res => res.data)
  },
  /**
   * 创建分润机构配置
   * @param params
   * @returns {*} $sysappApi.SHARE.fetchAddPartnerMerchantConfiguration
   */
  fetchAddPartnerMerchantConfiguration (params, data) {
    return axios.post(`${SYS_APP_PATH}/merchantShareRatio/save`, data, { params }).then(res => res.data)
  },
  /**
   * 通过地区和机构名称查找合作机构配置
   * @param params
   * @returns {*} $sysappApi.SHARE.fetchUpdateMerchantConfiguration
   */
  fetchUpdateMerchantConfiguration (params, data) {
    return axios.post(`${SYS_APP_PATH}/merchantShareRatio/findByArea`, data, { params }).then(res => res.data)
  },
  /**
   * 删除合作机构分润配置
   * @param params
   * @returns {*} $sysappApi.SHARE.fetchDeletePartnerMerchantConfiguration
   */
  fetchDeletePartnerMerchantConfiguration (params, data) {
    return axios.post(`${SYS_APP_PATH}/merchantShareRatio/delete`, data, { params }).then(res => res.data)
  },
  /**
   * 删除合作机构
   * @param params
   * @returns {*} $sysappApi.SHARE.fetchDeletePartnerMerchant
   */
  fetchDeletePartnerMerchant (params, data) {
    return axios.post(`${SYS_APP_PATH}/merchantShareRatio/deleteMerchantShare`, data, { params }).then(res => res.data)
  },
  /**
   * 查看分润机构配置信息
   * @param params
   * @returns {*} $sysappApi.SHARE.fetchGetPartnerMerchantConfiguration
   */
  fetchGetPartnerMerchantConfiguration (params, data) {
    return axios.post(`${SYS_APP_PATH}/merchantShareRatio/findMerchantShare`, data, { params }).then(res => res.data)
  }
}
