import axios from 'axios'
import { SYS_APP_PATH } from '../base-url'

export default {
  /**
   * 友情链接列表
   * @param params
   * @returns {*} $sysappApi.LINK.fetchPostLinkList
   */
  fetchPostLinkList (params, data) {
    return axios.post(`${SYS_APP_PATH}/link/list`, data, { params }).then(res => res.data)
  },
  /**
   * 创建/编辑分组
   * @param params
   * @returns {*} $sysappApi.LINK.fetchSaveGroup
   */
  fetchSaveGroup (params, data) {
    return axios.post(`${SYS_APP_PATH}/link/groupEdit`, data, { params }).then(res => res.data)
  },
  /**
   * 创建/编辑链接
   * @param params
   * @returns {*} $sysappApi.LINK.fetchSaveLink
   */
  fetchSaveLink (params, data) {
    return axios.post(`${SYS_APP_PATH}/link/edit`, data, { params }).then(res => res.data)
  },
  /**
   * 删除分组
   * @param params
   * @returns {*} $sysappApi.LINK.fetchDeleteGroup
   */
  fetchDeleteGroup (params, data) {
    return axios.post(`${SYS_APP_PATH}/link/groupDel`, data, { params }).then(res => res.data)
  },
  /**
   * 删除链接
   * @param params
   * @returns {*} $sysappApi.LINK.fetchDeleteLink
   */
  fetchDeleteLink (params, data) {
    return axios.post(`${SYS_APP_PATH}/link/delete`, data, { params }).then(res => res.data)
  }
}
