<template>
  <el-dialog
    ref="dialog"
    :visible.sync="dialogVisible"
    style="margin-top: -5vh;"
    :close-on-click-modal="false"
    custom-class="gtm-video-player"
    @open="onDialogOpen"
    :width="boxWidth"
    :height="boxHeight"
  >
    <div class="gtm-video-box">
      <div id="aliplayer"></div>
      <div class="close-button" @click="closeVideo">
        <i class="el-icon-close"></i>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { NX } from './aliplayer'

export default {
  name: 'GtmVideoPlayer',
  components: {},
  mixins: [],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    videoUrl: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      boxWidth: '',
      boxHeight: '',
      player: null
    }
  },
  computed: {
    dialogVisible: {
      get () {
        return this.visible
      },
      set () {
        this.$emit('update:visible', false)
      }
    }
  },
  methods: {
    onDialogOpen () {
      // 这个是生命周期的起点
      this.boxWidth = window.innerWidth * 0.5 / 16 + 'rem'
      this.boxHeight = window.innerWidth * 0.5 * 9 / 16 / 16 + 'rem'
      NX()
        .then(() => {
          /* eslint-disable */
          this.player = new Aliplayer({
            'id': 'aliplayer',
            'source': this.videoUrl,
            'width': this.boxWidth,
            'height': this.boxHeight,
            'autoplay': false,
            'isLive': false,
            'rePlay': false,
            'playsinline': true,
            'preload': true,
            'controlBarVisibility': 'hover',
            'useH5Prism': true
          })
        })
    },

    closeVideo () {
      this.player.dispose() // 销毁播放器
      this.player = null
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="scss">
.gtm-video-player {

  .el-dialog__body {
    padding: 0 !important;
  }

  .gtm-video-box {
    position: relative;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 16px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
