import axios from 'axios'
import { SYS_APP_PATH } from '../../base-url'

export default {
  /**
   * 获取帮助中心模块启用状态
   * @param params
   * @param data
   * @returns {any} $sysappApi.SETTING.fetchGetHelpModuleStatus
   */
  fetchGetHelpModuleStatus (params, data) {
    return axios.post(`${SYS_APP_PATH}/setting/help/module/status`, data, { params })
      .then(res => res.data)
  },
  /**
   * 是否启用帮助中心模块
   * @param params
   * @param data
   * @returns {any} $sysappApi.SETTING.fetchUpdateHelpModuleEnable
   */
  fetchUpdateHelpModuleEnable (params, data) {
    return axios.post(`${SYS_APP_PATH}/setting/help/module/enable`, data, { params })
      .then(res => res.data)
  }
}
