import axios from 'axios'
import { SYS_APP_PATH } from '../../base-url'

export default {
  /**
   * 租户配置-详细配置项
   * @param params
   * @returns {*} $sysappApi.SETTING.fetchGetTenantBusinessSetting
   */
  fetchGetTenantBusinessSetting (params) {
    return axios.get(`${SYS_APP_PATH}/setting/business/tenant`, { params })
      .then(res => res.data)
  },
  /**
   * 首页配置-首页详情
   * @param params
   * @returns {*} $sysappApi.SETTING.fetchGetHomeBusinessSetting
   */
  fetchGetHomeBusinessSetting (params) {
    return axios.get(`${SYS_APP_PATH}/setting/business/home`, { params })
      .then(res => res.data)
  },
  /**
   * 帮助中心-问答配置
   * @param params
   * @returns {*} $sysappApi.SETTING.fetchGetHelpQaSetting
   */
  fetchGetHelpQaSetting (params) {
    return axios.get(`${SYS_APP_PATH}/setting/business/qa`, { params })
      .then(res => res.data)
  },
  /**
   * 帮助中心-指引配置
   * @param params
   * @returns {*} $sysappApi.SETTING.fetchGetHelpGuideSetting
   */
  fetchGetHelpGuideSetting (params) {
    return axios.get(`${SYS_APP_PATH}/setting/business/help`, { params })
      .then(res => res.data)
  },
  /**
   * 保函申请-配置详情
   * @param params
   * @returns {*} $sysappApi.SETTING.fetchGetApplyBusinessSetting
   */
  fetchGetApplyBusinessSetting (params) {
    return axios.get(`${SYS_APP_PATH}/setting/business/apply`, { params })
      .then(res => res.data)
  }
}
