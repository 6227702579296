import axios from 'axios'
import { SYS_APP_PATH } from '../../base-url'

export default {
  /**
   * 获取保函申请条目数据
   * @param params
   * @returns {any} $sysappApi.SETTING.fetchGetGuaranteeItemList
   */
  fetchGetGuaranteeItemList (params) {
    return axios.get(`${SYS_APP_PATH}/setting/guarantee/item/list`, { params })
      .then(res => res.data)
  },
  /**
   * 更新保函申请条目数据
   * @param params
   * @param data
   * @returns {any} $sysappApi.SETTING.fetchUpdateGuaranteeItem
   */
  fetchUpdateGuaranteeItem (params, data) {
    return axios.post(`${SYS_APP_PATH}/setting/guarantee/item/edit`, data, { params })
      .then(res => res.data)
  }
}
