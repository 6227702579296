import axios from 'axios'
import { queryToParams, getCookie } from 'web-utils'
import { rootState } from '@gtm-butils'
import '@gtm-components/assets/iconfont/iconfont.css'
import { baseAppApi, sysappApi } from '@gtm-api'
import { tenantConfigList } from '@gtm-butils/root-state/tenantConfig'
import { setLocalValue } from '@gtm-butils/root-methods/app-context'

// 这里是请求原始租户信息
const requestUserTenantInfo = () => {
  let params = {}
  let query = queryToParams()
  const token = getCookie('SESSION_TOKEN')
  if (query?.token || token) {
    params = {
      token: query?.token || token
    }
  }
  return baseAppApi.USER.fetchGetUserTenant(params)
    .then(res => {
      return res
    })
}

// 请求游客IP地址
const requestCustomerIp = () => {
  let customerIp = localStorage.getItem('CUSTOMER_IP')
  if (!customerIp) {
    axios.get(`/source/addr`)
      .then(res => {
        if (res && res.data) {
          localStorage.setItem('CUSTOMER_IP', res.data)
        } else {
          localStorage.setItem('CUSTOMER_IP', Math.floor(10000000 + Math.random() * 90000000))
        }
      })
  }
}

// 初始化友情链接列表值
const linkListInit = (originalLinkList) => {
  return originalLinkList.map(group => {
    // 创建一个新对象，只包含name和转换后的links
    const transformedLinkList = {
      name: group.name,
      linkItemList: group.links.map(link => ({
        name: link.name,
        // 这里修正了原始数据中可能的键名错误（如"linksrc"改为"linkSrc"）
        linkSrc: link.url
      }))
    }
    return transformedLinkList
  })
}

// 请求全局租户配置
const requestTenantBusinessConfig = () => {
  return sysappApi.SETTING.fetchGetTenantBusinessSetting()
    .then(res => {
      return res
    })
}

// 同时请求两个接口
const startApp = () => {
  // 执行任务队列
  const taskList = [requestUserTenantInfo(), requestTenantBusinessConfig()]
  return Promise.all(taskList)
    .then(res => {
      // 原始租户相关信息
      const userTenantRes = res[0]
      // 全局租户配置相关信息
      const tenantBusinessRes = res[1] || {
        data: {
          styleTemplate: 'DEFAULT'
        }
      }

      // 原始租户进行全局存储
      rootState.tenantInfo = userTenantRes.data || {}
      setLocalValue('portal_root_tenantInfo', JSON.stringify(rootState.tenantInfo))
      sessionStorage.setItem('TENANT_DOMAIN', userTenantRes?.data?.domain || '')
      sessionStorage.setItem('TENANT_ORIGIN_DOMAIN', userTenantRes?.data?.originDomain || '')
      sessionStorage.setItem('TENANT_INFO', JSON.stringify(userTenantRes?.data))
      console.log('【base-platform】【main.js】获取租户原始域名：', userTenantRes?.data?.domain || '')

      // 租户全局配置全局存储【菜单】
      const sortMenus = ['Home', 'InstitutionList', 'NewsList', 'HelpCenter', 'About']
      let targetMenus = []
      const tenantMenus = tenantBusinessRes?.data?.menus ? tenantBusinessRes.data.menus.split(',') : []
      if (tenantMenus.length > 0) {
        sortMenus.forEach(item => {
          if (tenantMenus.includes(item)) {
            targetMenus.push(item)
          }
        })
      }
      rootState.tenantMenus = targetMenus.length > 0 ? targetMenus : ['Home', 'InstitutionList', 'NewsList', 'HelpCenter', 'About']
      setLocalValue('portal_root_tenantMenus', JSON.stringify(rootState.tenantMenus))
      // 根据租户全局配置，再结合本地配置，输出最终的配置文件信息
      if (tenantBusinessRes?.data?.styleTemplate === 'DEFAULT') {
        rootState.tenantConfig = {}
        sessionStorage.setItem('TENANT_STYLE_TEMPLATE', 'DEFAULT')
        setLocalValue('portal_root_tenantConfig', JSON.stringify(rootState.tenantConfig))
      } else if (tenantBusinessRes?.data?.styleTemplate === 'TRADE_PROFESSIONAL') {
        sessionStorage.setItem('TENANT_STYLE_TEMPLATE', 'TRADE_PROFESSIONAL')
        console.log('【base-platform】【main.js】生效交易配置', tenantBusinessRes.data.global)
        const localConfig = tenantConfigList.find(item => item.host === sessionStorage.getItem('TENANT_DOMAIN')) || {}
        rootState.tenantConfig = {
          header: {
            logoSrc: tenantBusinessRes?.data?.global?.logoSrc,
            platformName: tenantBusinessRes?.data?.global?.platformName
          },
          footer: {
            imageList: tenantBusinessRes?.data?.global?.imageList ? JSON.parse(tenantBusinessRes?.data?.global?.imageList) : [],
            platformInfo: {
              infoItemList: tenantBusinessRes?.data?.global?.platformInfo ? JSON.parse(tenantBusinessRes?.data?.global?.platformInfo) : []
            },
            linkList: tenantBusinessRes?.data?.global?.footerLinksEnable ? (tenantBusinessRes?.data?.footerLinks ? linkListInit(tenantBusinessRes.data.footerLinks) : []) : []
          }
        }
        if (localConfig?.home) {
          rootState.tenantConfig.home = localConfig?.home
        }
        if (localConfig?.institutionList) {
          rootState.tenantConfig.institutionList = localConfig?.institutionList
        }
        setLocalValue('portal_root_tenantConfig', JSON.stringify(rootState.tenantConfig))
      }
      console.log('【base-platform】【main.js】获取租户原始域名下的租户配置', rootState.tenantConfig)

      // 请求游客IP地址并全局设置
      requestCustomerIp()
    })
}

// 对外暴露
export { startApp }
