import axios from 'axios'
import { DEVELOPMENT_PATH } from '../../base-url'

export default {
  /**
   * 通过平台id查询平台信息
   * @param id
   * @param params
   * @returns {*} $developmentApi.OPEN_PLATFORM.fetchGetOpenPlatformById
   */
  fetchGetOpenPlatformById (id, params) {
    return axios.get(`${DEVELOPMENT_PATH}/openPlatform/find/${id}`, { params })
      .then(res => res.data)
  },
  /**
   * 平台列表
   * @param params
   * @param data
   * @returns {*} $developmentApi.OPEN_PLATFORM.fetchGetOpenPlatformList
   */
  fetchGetOpenPlatformList (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/openPlatform/list`, data, { params })
      .then(res => res.data)
  },
  /**
   * 删除平台
   * @param id
   * @returns {*} $developmentApi.OPEN_PLATFORM.fetchDeleteOpenPlatformById
   */
  fetchDeleteOpenPlatformById (id) {
    return axios.delete(`${DEVELOPMENT_PATH}/openPlatform/delete/${id}`)
      .then(res => res.data)
  },
  /**
   * 保存平台信息
   * @param params
   * @param data
   * @returns {*} $developmentApi.OPEN_PLATFORM.fetchSaveOpenPlatform
   */
  fetchSaveOpenPlatform (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/openPlatform/save`, data, { params })
      .then(res => res.data)
  }
}
