export const NX = (tinymceSourceSrc) => {
  let promist1 = new Promise(function (resolve, reject) {
    const aliplayerJs = document.getElementById('aliplayerJs')
    if (aliplayerJs) {
      resolve()
    } else {
      let script = document.createElement('script')
      script.type = 'text/javascript'
      script.setAttribute('id', 'aliplayerJs')
      script.setAttribute('charset', 'utf-8')
      script.src = 'https://g.alicdn.com/de/prismplayer/2.15.2/aliplayer-h5-min.js'
      script.onerror = reject
      let css = document.createElement('link')
      css.setAttribute('rel', 'stylesheet')
      css.setAttribute('href', 'https://g.alicdn.com/de/prismplayer/2.15.2/skins/default/aliplayer-min.css')
      document.head.appendChild(script)
      document.head.appendChild(css)
      script.onload = function () {
        resolve()
      }
    }
  })
  return Promise.all([promist1])
    .then(res => {
    })
}
