import axios from 'axios'
import { SYS_APP_PATH } from '../../base-url'

export default {
  /**
   * 获取保函申请模块启用状态
   * @param params
   * @param data
   * @returns {any} $sysappApi.SETTING.fetchGetGuaranteeModuleStatus
   */
  fetchGetGuaranteeModuleStatus (params) {
    return axios.get(`${SYS_APP_PATH}/setting/guarantee/module/status`, { params })
      .then(res => res.data)
  },
  /**
   * 是否启用保函申请模块
   * @param params
   * @param data
   * @returns {any} $sysappApi.SETTING.fetchUpdateGuaranteeModuleEnable
   */
  fetchUpdateGuaranteeModuleEnable (params, data) {
    return axios.post(`${SYS_APP_PATH}/setting/guarantee/module/enable`, data, { params })
      .then(res => res.data)
  }
}
