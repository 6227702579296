import axios from 'axios'
import { SYS_APP_PATH } from '../../base-url'

export default {
  /**
   * 查询banner列表
   * @param params
   * @returns {*} $sysappApi.ARTICLE.fetchGetArticleBannerList
   */
  fetchGetArticleBannerList (params, data) {
    return axios.post(`${SYS_APP_PATH}/article/banner/list`, data, { params })
      .then(res => res.data)
  },
  /**
   * 保存banner
   * @param params
   * @returns {*} $sysappApi.ARTICLE.fetchSaveArticleBanner
   */
  fetchSaveArticleBanner (params, data) {
    return axios.post(`${SYS_APP_PATH}/article/banner/save`, data, { params })
      .then(res => res.data)
  },
  /**
   * 删除banner
   * @param params
   * @returns {*} $sysappApi.ARTICLE.fetchDeleteArticleBanner
   */
  fetchDeleteArticleBanner (params, data) {
    return axios.post(`${SYS_APP_PATH}/article/banner/delete`, data, { params })
      .then(res => res.data)
  },
  /**
   * 查询文章关联的banner
   * @param params
   * @returns {*} $sysappApi.ARTICLE.fetchGetArticleLinkBanner
   */
  fetchGetArticleLinkBanner (params, data) {
    return axios.post(`${SYS_APP_PATH}/article/banner/queryByArticle`, data, { params })
      .then(res => res.data)
  }
}
