const tenantConfigList = [
  // 铜川场内租户 - 测试环境
  {
    // host: '219.142.102.242',
    host: '333.133.333.242',
    header: {
      logoSrc: 'tongchuan.png',
      platformName: '铜川市公共资源交易金融服务平台',
      menuList: ['InstitutionList']
    },
    footer: {
      linkList: [],
      imageList: [
        {
          imageSrc: 'trade-logo1.png',
          linkSrc: 'http://bszs.conac.cn/sitename?method=show&id=5D7052540FE83942E053022E1AAC48C2'
        },
        {
          imageSrc: 'trade-logo2.png',
          linkSrc: 'https://zfwzgl.www.gov.cn/exposure/jiucuo.html?site_code=6100000130&url=http%3A%2F%2Fwww.sxggzyjy.cn%2F%23'
        }
      ],
      platformInfo: {
        copyright: '主管单位：铜川市公共资源交易中心',
        infoItemList: [
          {
            name: '©Glodon Company Limited 1998-2020 All Rights Reserved. 广联达科技股份有限公司',
            linkSrc: ''
          },
          {
            name: 'ICP备案号：京ICP备10021606号-1',
            linkSrc: ''
          },
          {
            name: '京公网安备11010802021391号',
            linkSrc: ''
          },
          {
            name: '建议您使用Chrome、Microsoft Edge、Firefox、360极速版等主流浏览器浏览本网站',
            linkSrc: ''
          }
        ]
      }
    }
  },
  // 铜川场内租户 - 预生产环境
  {
    host: 'test-gyrpre.glodon.com',
    // host: 'prepre.glodon.com',
    header: {
      logoSrc: 'tongchuan.png',
      platformName: '铜川市公共资源交易金融服务平台',
      menuList: ['InstitutionList']
    },
    footer: {
      linkList: [],
      imageList: [
        {
          imageSrc: 'trade-logo1.png',
          linkSrc: 'http://bszs.conac.cn/sitename?method=show&id=5D7052540FE83942E053022E1AAC48C2'
        },
        {
          imageSrc: 'trade-logo2.png',
          linkSrc: 'https://zfwzgl.www.gov.cn/exposure/jiucuo.html?site_code=6100000130&url=http%3A%2F%2Fwww.sxggzyjy.cn%2F%23'
        }
      ],
      platformInfo: {
        copyright: '主管单位：铜川市公共资源交易中心',
        infoItemList: [
          {
            name: '©Glodon Company Limited 1998-2020 All Rights Reserved. 广联达科技股份有限公司',
            linkSrc: ''
          },
          {
            name: 'ICP备案号：京ICP备10021606号-1',
            linkSrc: ''
          },
          {
            name: '京公网安备11010802021391号',
            linkSrc: ''
          },
          {
            name: '建议您使用Chrome、Microsoft Edge、Firefox、360极速版等主流浏览器浏览本网站',
            linkSrc: ''
          }
        ]
      }
    }
  },
  // 铜川场内租户 - 生产环境
  {
    host: 'tongch-gyr.glodon.com',
    header: {
      logoSrc: 'tongchuan.png',
      platformName: '铜川市公共资源交易金融服务平台',
      menuList: ['InstitutionList']
    },
    footer: {
      linkList: [],
      imageList: [
        {
          imageSrc: 'trade-logo1.png',
          linkSrc: 'http://bszs.conac.cn/sitename?method=show&id=5D7052540FE83942E053022E1AAC48C2'
        },
        {
          imageSrc: 'trade-logo2.png',
          linkSrc: 'https://zfwzgl.www.gov.cn/exposure/jiucuo.html?site_code=6100000130&url=http%3A%2F%2Fwww.sxggzyjy.cn%2F%23'
        }
      ],
      platformInfo: {
        copyright: '主管单位：铜川市公共资源交易中心',
        infoItemList: [
          {
            name: '©Glodon Company Limited 1998-2020 All Rights Reserved. 广联达科技股份有限公司',
            linkSrc: ''
          },
          {
            name: 'ICP备案号：京ICP备10021606号-1',
            linkSrc: ''
          },
          {
            name: '京公网安备11010802021391号',
            linkSrc: ''
          },
          {
            name: '建议您使用Chrome、Microsoft Edge、Firefox、360极速版等主流浏览器浏览本网站',
            linkSrc: ''
          }
        ]
      }
    }
  },
  // 内蒙古租户 - 生产环境配置
  {
    host: 'neimeng-gyr.glodon.com',
    header: {
      logoSrc: '',
      platformName: '内蒙古工程建设金融服务平台',
      menuList: ['Home', 'InstitutionList']
    },
    footer: {
      linkList: [
        {
          name: '各级政府',
          linkItemList: [
            {
              name: '中国政府网',
              linkSrc: 'https://www.gov.cn/'
            },
            {
              name: '内蒙古自治区人民政府网站',
              linkSrc: 'https://www.nmg.gov.cn/'
            },
            {
              name: '鄂尔多斯人民政府网站',
              linkSrc: 'http://www.ordos.gov.cn/'
            }
          ]
        },
        {
          name: '公共资源交易中心',
          linkItemList: [
            {
              name: '内蒙古自治区公共资源交易中心',
              linkSrc: 'https://ggzyjy.nmg.gov.cn/'
            },
            {
              name: '鄂尔多斯市公共资源交易中心',
              linkSrc: 'http://www.ordosggzyjy.org.cn/'
            }
          ]
        },
        {
          name: '代建中心',
          linkItemList: [
            {
              name: '鄂尔多斯市政府投资项目代建中心',
              linkSrc: ' http://ordosdjzx.org.cn/'
            }
          ]
        }
      ],
      imageList: [
        {
          imageSrc: 'trade-logo1.png',
          linkSrc: 'http://bszs.conac.cn/sitename?method=show&id=5D7052540FE83942E053022E1AAC48C2'
        },
        {
          imageSrc: 'trade-logo2.png',
          linkSrc: 'https://zfwzgl.www.gov.cn/exposure/jiucuo.html?site_code=6100000130&url=http%3A%2F%2Fwww.sxggzyjy.cn%2F%23'
        }
      ],
      platformInfo: {
        copyright: '版权所有：广联达科技股份有限公司',
        infoItemList: [
          {
            name: '©Glodon Company Limited 1998-2020 All Rights Reserved. 广联达科技股份有限公司',
            linkSrc: ''
          },
          {
            name: 'ICP备案号：京ICP备10021606号-1',
            linkSrc: ''
          },
          {
            name: '京公网安备11010802021391号',
            linkSrc: ''
          },
          {
            name: '建议您使用Chrome、Microsoft Edge、Firefox、360极速版等主流浏览器浏览本网站',
            linkSrc: ''
          }
        ]
      }
    },
    home: {
      carouselList: [
        {
          'imgSrc': 'eeds-banner-1.jpg'
        },
        {
          'imgSrc': 'bannerThree1.jpg'
        },
        {
          'imgSrc': 'eeds-banner-3.jpg'
        }
      ],
      character: 'eeds-character',
      product: {
        title: '保函服务',
        subTitleList: ['安全', '稳定', '高效'],
        productList: ['PERFORMANCE', 'QUALITY', 'PAYMENT']
      },
      serviceAdvantage: [
        {
          title: '极速出函',
          description: '银行保函、保险保单、担保保函任意选择，即用即申请、秒出函、无须等待'
        },
        {
          title: '在线办理',
          description: '全程线上办理，足不出户，节省时间、节省企业人力和交通成本'
        },
        {
          title: '全面覆盖',
          description: '平台服务全面覆盖代建中心项目，官方认可、安全可靠'
        },
        {
          title: '贴心服务',
          description: '7×24小时智能客服加人工客服，保障平台使用全程无忧'
        }
      ],
      serviceCase: {
        show: false
      },
      partner: {
        show: true,
        componentName: 'static',
        height: '36.31rem',
        title: '入驻金融机构',
        subTitle: ['银行', '担保机构'],
        bankList: [
          {
            url: 'jianhang.png'
          },
          {
            url: 'shanghai.png'
          },
          {
            url: 'guohuadanbao.png'
          },
          {
            url: 'zhongtoubao.png'
          }
        ]
      },
      seekAdvice: '保函产品、价格、申请或其他任何相关事宜，有疑问？我们随时为您答疑解惑！'
    },
    institutionList: {
      performanceBanner: 'banner-performance--eeds'
    }
  },
  // 渭南租户 - 测试环境配置
  {
    host: '219.142.102.242',
    header: {
      platformName: '渭南市公共资源交易金融服务平台',
      menuList: ['InstitutionList']
    },
    footer: {
      linkList: [],
      imageList: [
        {
          imageSrc: 'trade-logo1.png',
          linkSrc: 'http://bszs.conac.cn/sitename?method=show&id=5D7052540FE83942E053022E1AAC48C2'
        },
        {
          imageSrc: 'trade-logo2.png',
          linkSrc: 'https://zfwzgl.www.gov.cn/exposure/jiucuo.html?site_code=6100000130&url=http%3A%2F%2Fwww.sxggzyjy.cn%2F%23'
        }
      ],
      platformInfo: {
        copyright: '主管单位：渭南市公共资源交易中心',
        infoItemList: [
          {
            name: '©Glodon Company Limited 1998-2020 All Rights Reserved. 广联达科技股份有限公司',
            linkSrc: ''
          },
          {
            name: 'ICP备案号：京ICP备10021606号-1',
            linkSrc: ''
          },
          {
            name: '京公网安备11010802021391号',
            linkSrc: ''
          },
          {
            name: '建议您使用Chrome、Microsoft Edge、Firefox、360极速版等浏览器浏览本网站',
            linkSrc: ''
          }
        ]
      }
    },
    home: {
      carouselList: [
        {
          'imgSrc': 'eeds-banner-1.jpg'
        },
        {
          'imgSrc': 'bannerThree1.jpg'
        },
        {
          'imgSrc': 'eeds-banner-3.jpg'
        }
      ],
      character: 'eeds-character',
      product: {
        title: '保函服务',
        subTitleList: ['安全', '稳定', '高效'],
        productList: ['PERFORMANCE', 'QUALITY', 'PAYMENT']
      },
      serviceAdvantage: [
        {
          title: '极速出函',
          description: '银行保函、保险保单、担保保函任意选择，即用即申请、秒出函、无须等待'
        },
        {
          title: '在线办理',
          description: '全程线上办理，足不出户，节省时间、节省企业人力和交通成本'
        },
        {
          title: '全面覆盖',
          description: '平台服务全面覆盖代建中心项目，官方认可、安全可靠'
        },
        {
          title: '贴心服务',
          description: '7×24小时智能客服加人工客服，保障平台使用全程无忧'
        }
      ],
      serviceCase: {
        show: false
      },
      partner: {
        show: true,
        componentName: 'static',
        height: '36.31rem',
        title: '入驻金融机构',
        subTitle: ['银行', '担保机构'],
        bankList: [
          {
            url: 'jianhang.png'
          },
          {
            url: 'shanghai.png'
          },
          {
            url: 'guohuadanbao.png'
          },
          {
            url: 'zhongtoubao.png'
          }
        ]
      },
      seekAdvice: '保函产品、价格、申请或其他任何相关事宜，有疑问？我们随时为您答疑解惑！'
    },
    institutionList: {
      performanceBanner: 'banner-performance--eeds'
    }
  },
  // 渭南场内 - 预生产生产配置
  {
    host: 'open-gyrpre.glodon.com',
    header: {
      platformName: '渭南市公共资源交易金融服务平台',
      menuList: ['InstitutionList']
    },
    footer: {
      linkList: [],
      imageList: [
        {
          imageSrc: 'trade-logo1.png',
          linkSrc: 'http://bszs.conac.cn/sitename?method=show&id=5D7052540FE83942E053022E1AAC48C2'
        },
        {
          imageSrc: 'trade-logo2.png',
          linkSrc: 'https://zfwzgl.www.gov.cn/exposure/jiucuo.html?site_code=6100000130&url=http%3A%2F%2Fwww.sxggzyjy.cn%2F%23'
        }
      ],
      platformInfo: {
        copyright: '主管单位：渭南市公共资源交易中心',
        infoItemList: [
          {
            name: '©Glodon Company Limited 1998-2020 All Rights Reserved. 广联达科技股份有限公司',
            linkSrc: ''
          },
          {
            name: 'ICP备案号：京ICP备10021606号-1',
            linkSrc: ''
          },
          {
            name: '京公网安备11010802021391号',
            linkSrc: ''
          },
          {
            name: '建议您使用Chrome、Microsoft Edge、Firefox、360极速版等浏览器浏览本网站',
            linkSrc: ''
          }
        ]
      }
    },
    home: {
      carouselList: [
        {
          'imgSrc': 'eeds-banner-1.jpg'
        },
        {
          'imgSrc': 'bannerThree1.jpg'
        },
        {
          'imgSrc': 'eeds-banner-3.jpg'
        }
      ],
      character: 'eeds-character',
      product: {
        title: '保函服务',
        subTitleList: ['安全', '稳定', '高效'],
        productList: ['PERFORMANCE', 'QUALITY', 'PAYMENT']
      },
      serviceAdvantage: [
        {
          title: '极速出函',
          description: '银行保函、保险保单、担保保函任意选择，即用即申请、秒出函、无须等待'
        },
        {
          title: '在线办理',
          description: '全程线上办理，足不出户，节省时间、节省企业人力和交通成本'
        },
        {
          title: '全面覆盖',
          description: '平台服务全面覆盖代建中心项目，官方认可、安全可靠'
        },
        {
          title: '贴心服务',
          description: '7×24小时智能客服加人工客服，保障平台使用全程无忧'
        }
      ],
      serviceCase: {
        show: false
      },
      partner: {
        show: true,
        componentName: 'static',
        height: '36.31rem',
        title: '入驻金融机构',
        subTitle: ['银行', '担保机构'],
        bankList: [
          {
            url: 'jianhang.png'
          },
          {
            url: 'shanghai.png'
          },
          {
            url: 'guohuadanbao.png'
          },
          {
            url: 'zhongtoubao.png'
          }
        ]
      },
      seekAdvice: '保函产品、价格、申请或其他任何相关事宜，有疑问？我们随时为您答疑解惑！'
    },
    institutionList: {
      performanceBanner: 'banner-performance--eeds'
    }
  },
  // 渭南场内 - 生产配置
  {
    host: 'weinan-gyr.glodon.com',
    header: {
      platformName: '渭南市公共资源交易金融服务平台',
      menuList: ['InstitutionList']
    },
    footer: {
      linkList: [],
      imageList: [
        {
          imageSrc: 'trade-logo1.png',
          linkSrc: 'http://bszs.conac.cn/sitename?method=show&id=5D7052540FE83942E053022E1AAC48C2'
        },
        {
          imageSrc: 'trade-logo2.png',
          linkSrc: 'https://zfwzgl.www.gov.cn/exposure/jiucuo.html?site_code=6100000130&url=http%3A%2F%2Fwww.sxggzyjy.cn%2F%23'
        }
      ],
      platformInfo: {
        copyright: '主管单位：渭南市公共资源交易中心',
        infoItemList: [
          {
            name: '©Glodon Company Limited 1998-2020 All Rights Reserved. 广联达科技股份有限公司',
            linkSrc: ''
          },
          {
            name: 'ICP备案号：京ICP备10021606号-1',
            linkSrc: ''
          },
          {
            name: '京公网安备11010802021391号',
            linkSrc: ''
          },
          {
            name: '建议您使用Chrome、Microsoft Edge、Firefox、360极速版等浏览器浏览本网站',
            linkSrc: ''
          }
        ]
      }
    },
    home: {
      carouselList: [
        {
          'imgSrc': 'eeds-banner-1.jpg'
        },
        {
          'imgSrc': 'bannerThree1.jpg'
        },
        {
          'imgSrc': 'eeds-banner-3.jpg'
        }
      ],
      character: 'eeds-character',
      product: {
        title: '保函服务',
        subTitleList: ['安全', '稳定', '高效'],
        productList: ['PERFORMANCE', 'QUALITY', 'PAYMENT']
      },
      serviceAdvantage: [
        {
          title: '极速出函',
          description: '银行保函、保险保单、担保保函任意选择，即用即申请、秒出函、无须等待'
        },
        {
          title: '在线办理',
          description: '全程线上办理，足不出户，节省时间、节省企业人力和交通成本'
        },
        {
          title: '全面覆盖',
          description: '平台服务全面覆盖代建中心项目，官方认可、安全可靠'
        },
        {
          title: '贴心服务',
          description: '7×24小时智能客服加人工客服，保障平台使用全程无忧'
        }
      ],
      serviceCase: {
        show: false
      },
      partner: {
        show: true,
        componentName: 'static',
        height: '36.31rem',
        title: '入驻金融机构',
        subTitle: ['银行', '担保机构'],
        bankList: [
          {
            url: 'jianhang.png'
          },
          {
            url: 'shanghai.png'
          },
          {
            url: 'guohuadanbao.png'
          },
          {
            url: 'zhongtoubao.png'
          }
        ]
      },
      seekAdvice: '保函产品、价格、申请或其他任何相关事宜，有疑问？我们随时为您答疑解惑！'
    },
    institutionList: {
      performanceBanner: 'banner-performance--eeds'
    }
  },
  // 内蒙古租户 - 预生产环境配置
  {
    host: 'neimeng-gyrpre.glodon.com',
    header: {
      logoSrc: '',
      platformName: '内蒙古工程建设金融服务平台',
      menuList: ['Home', 'InstitutionList']
    },
    footer: {
      linkList: [
        {
          name: '各级政府',
          linkItemList: [
            {
              name: '中国政府网',
              linkSrc: 'https://www.gov.cn/'
            },
            {
              name: '内蒙古自治区人民政府网站',
              linkSrc: 'https://www.nmg.gov.cn/'
            },
            {
              name: '鄂尔多斯人民政府网站',
              linkSrc: 'http://www.ordos.gov.cn/'
            }
          ]
        },
        {
          name: '公共资源交易中心',
          linkItemList: [
            {
              name: '内蒙古自治区公共资源交易中心',
              linkSrc: 'https://ggzyjy.nmg.gov.cn/'
            },
            {
              name: '鄂尔多斯市公共资源交易中心',
              linkSrc: 'http://www.ordosggzyjy.org.cn/'
            }
          ]
        },
        {
          name: '代建中心',
          linkItemList: [
            {
              name: '鄂尔多斯市政府投资项目代建中心',
              linkSrc: ' http://ordosdjzx.org.cn/'
            }
          ]
        }
      ],
      imageList: [
        {
          imageSrc: 'trade-logo1.png',
          linkSrc: 'http://bszs.conac.cn/sitename?method=show&id=5D7052540FE83942E053022E1AAC48C2'
        },
        {
          imageSrc: 'trade-logo2.png',
          linkSrc: 'https://zfwzgl.www.gov.cn/exposure/jiucuo.html?site_code=6100000130&url=http%3A%2F%2Fwww.sxggzyjy.cn%2F%23'
        }
      ],
      platformInfo: {
        copyright: '版权所有：广联达科技股份有限公司',
        infoItemList: [
          {
            name: '©Glodon Company Limited 1998-2020 All Rights Reserved. 广联达科技股份有限公司',
            linkSrc: ''
          },
          {
            name: 'ICP备案号：京ICP备10021606号-1',
            linkSrc: ''
          },
          {
            name: '京公网安备11010802021391号',
            linkSrc: ''
          },
          {
            name: '建议您使用Chrome、Microsoft Edge、Firefox、360极速版等主流浏览器浏览本网站',
            linkSrc: ''
          }
        ]
      }
    },
    home: {
      carouselList: [
        {
          'imgSrc': 'eeds-banner-1.jpg'
        },
        {
          'imgSrc': 'bannerThree1.jpg'
        },
        {
          'imgSrc': 'eeds-banner-3.jpg'
        }
      ],
      character: 'eeds-character',
      product: {
        title: '保函服务',
        subTitleList: ['安全', '稳定', '高效'],
        productList: ['PERFORMANCE', 'QUALITY', 'PAYMENT']
      },
      serviceAdvantage: [
        {
          title: '极速出函',
          description: '银行保函、保险保单、担保保函任意选择，即用即申请、秒出函、无须等待'
        },
        {
          title: '在线办理',
          description: '全程线上办理，足不出户，节省时间、节省企业人力和交通成本'
        },
        {
          title: '全面覆盖',
          description: '平台服务全面覆盖代建中心项目，官方认可、安全可靠'
        },
        {
          title: '贴心服务',
          description: '7×24小时智能客服加人工客服，保障平台使用全程无忧'
        }
      ],
      serviceCase: {
        show: false
      },
      partner: {
        show: true,
        componentName: 'static',
        height: '36.31rem',
        title: '入驻金融机构',
        subTitle: ['银行', '担保机构'],
        bankList: [
          {
            url: 'jianhang.png'
          },
          {
            url: 'shanghai.png'
          },
          {
            url: 'guohuadanbao.png'
          },
          {
            url: 'zhongtoubao.png'
          }
        ]
      },
      seekAdvice: '保函产品、价格、申请或其他任何相关事宜，有疑问？我们随时为您答疑解惑！'
    },
    institutionList: {
      performanceBanner: 'banner-performance--eeds'
    }
  },
  // 西咸金服 - 测试配置
  {
    host: 'xixian-gyrpre.glodon.com',
    header: {
      platformName: '西咸市公共资源交易金融服务平台',
      menuList: ['InstitutionList']
    },
    footer: {
      linkList: [],
      imageList: [],
      platformInfo: {
        copyright: '主管单位：西咸新区公共资源交易中心',
        infoItemList: [
          {
            name: '©Glodon Company Limited 1998-2020 All Rights Reserved. 广联达科技股份有限公司',
            linkSrc: ''
          },
          {
            name: 'ICP备案号：京ICP备10021606号-1',
            linkSrc: ''
          },
          {
            name: '京公网安备11010802021391号',
            linkSrc: ''
          },
          {
            name: '建议您使用Chrome、Microsoft Edge、Firefox、360极速版等浏览器浏览本网站',
            linkSrc: ''
          }
        ]
      }
    }
  },
  // 西咸金服 - 生产配置
  {
    host: 'xixian-gyr.glodon.com',
    header: {
      platformName: '西咸市公共资源交易金融服务平台',
      menuList: ['InstitutionList']
    },
    footer: {
      linkList: [],
      imageList: [],
      platformInfo: {
        copyright: '主管单位：西咸新区公共资源交易中心',
        infoItemList: [
          {
            name: '©Glodon Company Limited 1998-2020 All Rights Reserved. 广联达科技股份有限公司',
            linkSrc: ''
          },
          {
            name: 'ICP备案号：京ICP备10021606号-1',
            linkSrc: ''
          },
          {
            name: '京公网安备11010802021391号',
            linkSrc: ''
          },
          {
            name: '建议您使用Chrome、Microsoft Edge、Firefox、360极速版等浏览器浏览本网站',
            linkSrc: ''
          }
        ]
      }
    }
  }
]

export { tenantConfigList }
