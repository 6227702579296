import axios from 'axios'
import { SYS_APP_PATH } from '../../base-url'

export default {
  /**
   * 获取首页模块数据
   * @param params
   * @returns {any} $sysappApi.SETTING.fetchGetHomeModule
   */
  fetchGetHomeModule (params) {
    return axios.get(`${SYS_APP_PATH}/setting/home/module/find`, { params })
      .then(res => res.data)
  },
  /**
   * 保存首页模块数据
   * @param params
   * @param data
   * @returns {any} $sysappApi.SETTING.fetchSaveHomeModule
   */
  fetchSaveHomeModule (params, data) {
    return axios.post(`${SYS_APP_PATH}/setting/home/module/save`, data, { params })
      .then(res => res.data)
  },
  /**
   * 首页模块启用状态
   * @param params
   * @param data
   * @returns {any} $sysappApi.SETTING.fetchGetHomeModuleStatus
   */
  fetchGetHomeModuleStatus (params, data) {
    return axios.post(`${SYS_APP_PATH}/setting/home/module/status`, data, { params })
      .then(res => res.data)
  },
  /**
   * 是否启用首页模块
   * @param params
   * @param data
   * @returns {any} $sysappApi.SETTING.fetchGetHomeModuleEnable
   */
  fetchGetHomeModuleEnable (params, data) {
    return axios.post(`${SYS_APP_PATH}/setting/home/module/enable`, data, { params })
      .then(res => res.data)
  }
}
