import settingBusiness from './setting-business'
import settingGlobal from './setting-global'
import settingHome from './setting-home'
import settingHomeItem from './setting-home-item'

// 帮助中心
import settingHelpModule from './setting-help-module'
import settingHelpItem from './setting-help-item'
import settingHelpQa from './setting-help-qa'

// 保函申请
import settingGuaranteeModule from './setting-guarantee-module'
import settingGuaranteeItem from './setting-guarantee-item'

export default {
  ...settingBusiness,
  ...settingGlobal,
  ...settingHome,
  ...settingHomeItem,
  ...settingHelpModule,
  ...settingHelpItem,
  ...settingHelpQa,
  ...settingGuaranteeModule,
  ...settingGuaranteeItem
}
