import axios from 'axios'
import { DEVELOPMENT_PATH } from '../../base-url'

export default {
  /**
   * 平台产品列表
   * @param params
   * @param data
   * @returns {*} $developmentApi.OPEN_PLATFORM.fetchGetProductListByPlatformId
   */
  fetchGetProductListByPlatformId (platformId) {
    return axios.get(`${DEVELOPMENT_PATH}/openPlatform/product/list/${platformId}`)
      .then(res => res.data)
  },
  /**
   * 删除平台下产品
   * @param id
   * @returns {*} $developmentApi.OPEN_PLATFORM.fetchDeleteOpenPlatformProductById
   */
  fetchDeleteOpenPlatformProductById (id) {
    return axios.delete(`${DEVELOPMENT_PATH}/openPlatform/product/delete/${id}`)
      .then(res => res.data)
  },
  /**
   * 保存平台产品信息
   * @param params
   * @param data
   * @returns {*} $developmentApi.OPEN_PLATFORM.fetchSaveOpenPlatformProduct
   */
  fetchSaveOpenPlatformProduct (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/openPlatform/product/save`, data, { params })
      .then(res => res.data)
  },
  /**
   * 通过平台编码和产品编码获取交易版本信息
   * @param params
   * @param data
   * @returns {*} $developmentApi.OPEN_PLATFORM.fetchGetOpenPlatformProductVersions
   */
  fetchGetOpenPlatformProductVersions (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/openPlatform/product/queryPlatformProductVersions`, data, { params })
      .then(res => res.data)
  }
}
