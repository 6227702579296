import {
  provinceAndCityData,
  pcTextArr,
  regionData,
  pcaTextArr,
  CodeToText
} from 'element-china-area-data'
import { deepClone } from './gtm-tool'

let gtmCityData = {
  provinceAndCityData,
  pcTextArr,
  regionData,
  pcaTextArr,
  CodeToText
}

// 在数据源里添加地区数据
const gtmAddCity = (provinceValue, cityValue, cityName) => {
  let provinceData = gtmCityData.provinceAndCityData.find(item => item.value === provinceValue)
  provinceData.children.push({
    label: cityName,
    value: cityValue
  })
}

const gtmAddProvincialLevel = () => {
  let regionalData = deepClone(gtmCityData.provinceAndCityData) // 深拷贝
  let directlyCityList = ['110000', '120000', '310000', '500000'] // 直辖市编码
  let fullProvinceData = regionalData.map(item => {
    if (!directlyCityList.includes(item.value)) {
      item.children.unshift({ value: item.value, label: '省本级' })
    }
    // else {
    //   item.children = [{ value: item.value, label: item.label }]
    // }
    return item
  })
  return [{ value: '000000', label: '全国' }, ...fullProvinceData]
}

// 编码转文字
function buildCodeToTextMap (data) {
  const codeToText = {}

  function traverse (items, parentValue = null) {
    for (const item of items) {
      // 如果当前项是顶级项，或者当前项的编码与父级编码不同，则添加到映射中
      if (parentValue === null || item.value !== parentValue) {
        codeToText[item.value] = item.label
      }

      // 递归遍历子项，传入当前项的编码作为父级编码
      if (item.children && item.children.length > 0) {
        traverse(item.children, item.value)
      }
    }
  }
  traverse(data)
  return codeToText
}

gtmCityData.fullProvinceData = gtmAddProvincialLevel()
// 给河北添加雄安新区
gtmAddCity('130000', '133100', '‌雄安新区')

console.log('【gtm-city-data】gtmCityData', gtmCityData)
export {
  gtmCityData, buildCodeToTextMap
}
