import axios from 'axios'
import { SYS_APP_PATH } from '../../base-url'

export default {
  /**
   * 查询文章列表
   * @param params
   * @returns {*} $sysappApi.ARTICLE.fetchGetArticleBusinessList
   */
  fetchGetArticleBusinessList (params, data) {
    return axios.post(`${SYS_APP_PATH}/article/business/list`, data, { params })
      .then(res => res.data)
  },
  /**
   * 查询文章精简列表（用于上一篇下一篇展示）
   * @param params
   * @returns {*} $sysappApi.ARTICLE.fetchGetArticleBusinessSimpleList
   */
  fetchGetArticleBusinessSimpleList (params, data) {
    return axios.post(`${SYS_APP_PATH}/article/business/simpleList`, data, { params })
      .then(res => res.data)
  },
  /**
   * 查询BANNER列表
   * @param params
   * @returns {*} $sysappApi.ARTICLE.fetchGetArticleBusinessBanner
   */
  fetchGetArticleBusinessBanner (params, data) {
    return axios.get(`${SYS_APP_PATH}/article/business/banner`, data, { params })
      .then(res => res.data)
  },
  /**
   * 获取文章详情
   * @param params
   * @returns {*} $sysappApi.ARTICLE.fetchGetArticleDetailById
   */
  fetchGetArticleDetailById (params) {
    return axios.get(`${SYS_APP_PATH}/article/business/view`, { params })
      .then(res => res.data)
  }
}
