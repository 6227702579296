<!-- 头部组件 -->
<template>
  <div class="headerContainer">
    <div class="container">
      <div class="left">
        <div class="logo-box" v-show="tenantLogoShow">
          <img v-show="isLogoShow" ref="logoImg" @load="scaleImage" :src="headerLogoSrc" alt="" class="image"/>
        </div>
        <div class="platform-name-box">{{ headerText }}</div>
      </div>
      <div class="midden">
        <div
          v-show="showMenu"
          class="listRow"
          :class="{ isActive: item.isActive }"
          v-for="(item, index) in listData"
          :key="index"
          @click="onMenuClick(item.appName, item.path)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="right" v-if="renderUserInfo && showUserInfo">
        <div v-if="userInfo.userId" style="display: flex">
          <!--          <el-button style="display: none" class="gtm-message-box" type="text" v-if="isOrderBtn" @click="onGoToMessage">-->
          <!--            <div>-->
          <!--              <el-badge is-dot class="item">-->
          <!--                <i class="el-icon-news"></i>-->
          <!--              </el-badge>-->
          <!--            </div>-->
          <!--            <div class="order-text">通知公告</div>-->
          <!--          </el-button>-->
          <el-button class="gtm-user-order-box" type="text" v-if="isOrderBtn" @click="onGoToUserOrderList">
            <div><i class="gtm-iconfont icon-dingdanguanli"></i></div>
            <div class="order-text">我的申请保函</div>
          </el-button>
          <div class="loginClass" v-if="!showDropdownMenu" :class="!showDropdownMenu ? 'no-pointer' : ''">
            <div class="logo"></div>
            <div class="user">{{ userInfo.loginName }}</div>
          </div>
          <el-dropdown v-if="showDropdownMenu">
            <span>
              <div class="loginClass">
                <div class="logo"></div>
                <div class="user">{{ userInfo.loginName }}</div>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </div>
            </span>
            <el-dropdown-menu slot="dropdown" class="dropdowns">
              <div class="userInfoName">{{ userInfo.loginName }}</div>
              <div class="hr"></div>
              <el-menu
                :default-openeds="userMenus"
                :default-active="activePath"
                class="el-menu-vertical"
                @select="handleMenuSelect"
              >
                <template>
                  <div v-for="(items, index) in secondList" :key="index">
                    <el-menu-item v-if="items.resUrl && items.children.length === 0" :index="items.resUrl">
                      <i style="display: inline-block" :class="items.iconCssClass"></i>
                      <span style="display: inline-block" slot="title">{{ items.resName }}</span>
                    </el-menu-item>
                    <el-submenu v-else :index="items.resId">
                      <template slot="title">
                        <i class="el-icon-setting bg-icon"></i>
                        <span>{{ items.resName }}</span>
                      </template>
                      <el-menu-item
                        :index="item.resUrl" v-for="(item, order) in items.children" :key="order"
                      >{{ item.resName }}
                      </el-menu-item>
                    </el-submenu>
                  </div>
                </template>
              </el-menu>
              <div class="loginOut" @click="onLoginOut">
                <span class="text">
                  <i
                    style="display: inline-block; vertical-align: top"
                    class="gtm-iconfont icon-a-arrow-downbeifen40"
                  ></i>
                  <div class="login-out-text">退出登录</div>
                </span>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="noLoginClass" v-else>
          <el-button v-show="showHomeBtn" type="text" style="color: #fff" @click="onLoginClick">返回登录</el-button>
          <el-button @click="onGoToRegister" class="register-btn" type="text" v-show="showLoginBtn">注册</el-button>
          <el-button @click="onGoToLogin" class="login-btn" v-show="showLoginBtn">登录</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { portalMenu } from '@/menu/platform-menu'
import { rootState } from '@gtm-butils'

export default {
  name: 'HeaderCom',
  props: {
    showMenu: {
      type: Boolean,
      default: true
    },
    showHomeBtn: {
      type: Boolean,
      default: false
    },
    showLoginBtn: {
      type: Boolean,
      default: false
    },
    isContextLoaded: {
      type: Boolean,
      default: false
    },
    showUserInfo: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      headerLogoSrc: '',
      isLogoShow: false,
      tenantLogoShow: true,
      isLoginBool: false,
      isFirstLoad: true,
      listData: portalMenu.filter(item => {
        let currentHostMenuList = rootState?.tenantMenus
        return currentHostMenuList.includes(item.routeName)
      }),
      headerText: '广联达金服平台',
      userInfo: {},
      secondList: [],
      userMenus: [],
      currentRootState: {},
      renderUserInfo: false,
      showDropdownMenu: true
    }
  },
  components: {},

  computed: {
    ...mapState(['rootRouterInfo', 'rootMicroAppData', 'rootFullPath', 'rootActivePath']),
    activePath () {
      return this.rootActivePath || this.rootFullPath
    },
    isOrderBtn () {
      let isOrderBtn = this.secondList.find(item => item.resUrl === 'guarantee-web/userOrderList')
      return Boolean(isOrderBtn?.resId)
    }
  },
  mounted () {
    console.log(this.listData, 'listData')
    console.log(this.userInfo, 'userInfo', rootState)
    console.log(this.$refs.logoImg)
    // this.scaleImage()
    this.currentRootState = rootState
    this.isFirstLoad = false
    if (this.$route?.query?.loginOut === 'trade') {
      this.onLoginOut()
    }
  },
  watch: {
    isContextLoaded: {
      handler: function (value) {
        if (value) {
          this.renderUserInfo = true
        }
      },
      deep: true,
      immediate: true
    },
    currentRootState: {
      handler: function (value) {
        this.userInfo = this.$rootState?.userInfo || {}
        this.secondList = this.$rootState?.indexInfo?.menus?.children || []
        this.userMenus = this.secondList.map((item, index) => index + '')
        this.listData.forEach(item => {
          item.isActive = `${item.appName}${item.path}` === (this.rootActivePath || this.rootFullPath)
        })
        if (this.userInfo.dataFromType === 'TRADE') {
          this.showDropdownMenu = false
        }
        console.log(this.$rootState)
        if (this.$rootState.indexInfo?.sysInfo?.systemCode === 'data-app') {
          this.headerLogoSrc = require('@/assets/images/home/logo-white.png')
        } else {
          if (this.$rootState?.tenantConfig?.header?.logoSrc) {
            this.headerLogoSrc = this.$rootState?.tenantConfig?.header?.logoSrc
          } else if (this.$rootState?.tenantConfig?.header?.logoSrc === '') {
            // 这里是要兼容无logo的情况
            this.tenantLogoShow = false
          } else {
            this.headerLogoSrc = require('@/assets/images/home/logo-white.png')
          }
          const appName = sessionStorage.getItem('portal_root_appName') || ''
          if (this.$rootState?.tenantConfig?.header?.platformName) {
            if (appName && (appName.includes('development') || appName.includes('backend'))) {
              this.headerText = '广联达金服平台'
              document.title = '广联达金服平台'
              this.headerLogoSrc = require('@/assets/images/home/logo-white.png')
            } else if (appName && appName.includes('bi')) {
              this.headerText = '金服运营驾驶舱'
              document.title = '金服运营驾驶舱'
              this.headerLogoSrc = require('@/assets/images/home/logo-white.png')
            } else {
              this.headerText = this.$rootState?.tenantConfig?.header?.platformName
              document.title = this.$rootState?.tenantConfig?.header?.platformName
            }
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 等比缩放logo图片
    scaleImage () {
      const img = this.$refs.logoImg
      // 获取图片原始的宽高
      const imgWidth = img.naturalWidth
      const imgHeight = img.naturalHeight
      // 计算宽度和高度的缩放比例
      const scaleWidth = 96 / imgWidth
      const scaleHeight = 44 / imgHeight
      // 使用较小的比例，以确保图片完全可见
      const scale = Math.min(scaleWidth, scaleHeight)
      // 应用新的宽度和高度
      img.style.width = `${imgWidth * scale / 16}rem`
      img.style.height = `${imgHeight * scale / 16}rem`
      // 如果需要水平和垂直居中
      // img.style.position = 'absolute'
      // img.style.top = '50%'
      // img.style.transform = 'translateY(-50%)'
      this.isLogoShow = true
    },
    // 首页菜单点击触发
    onMenuClick (appName, path) {
      if (`${appName}${path}` === 'guarantee-web/institutionList') {
        this.$rootMethods.DATA_TRACKING.sendTrackData('1513003')
      }
      this.$router.push({
        path: `/platform/${appName}${path}`
      })
    },
    // 点击返回首页
    onHomeClick () {
      this.$rootMethods.TOOLS.microRoutePush('platform', '/', this.$router)
    },
    // 注册
    onRegisterClick () {
      this.$rootMethods.TOOLS.microRoutePush('platform', '/register', this.$router)
    },
    onLoginClick () {
      this.$router.push({ name: 'Login' })
    },
    onGoToRegister () {
      this.$router.push({ name: 'GtmLogin', query: { type: 'register' } })
    },
    onGoToLogin () {
      this.$router.push({ name: 'GtmLogin', query: { type: 'login' } })
    },
    onGoToUserOrderList () {
      this.$rootMethods.DATA_TRACKING.sendTrackData('1513004')
      this.$router.push({
        path: '/platform/guarantee-web/userOrderList'
      })
    },
    // onGoToMessage () {
    //   this.$router.push({
    //     path: '/platform/message'
    //   })
    // },
    // 菜单点击
    handleMenuSelect (path) {
      this.$router.push({
        path: `/platform/${path}`
      })
    },
    // 先退出项管平台；然后退出自己平台
    async onLoginOut () {
      let path = ''
      if (process.env.VUE_APP_ENV === 'production') {
        path = 'https://xmgl.glodon.com'
      } else {
        path = 'https://aecloud-test.glodon.com'
      }
      let params = {
        userToken: 1,
        serviceUrl: path
      }
      this.$guaranteeApi.PORTAL.fetchPortalLoginOut(path, params)
      this.$guaranteeApi.COMMON_LOGIN.fetchToLoginOut({})
        .finally(() => {
          this.doLoginOut()
        })
    },
    doLoginOut () {
      setTimeout(() => {
        // 清除本地记录的用户信息
        let toRoutePath = ''
        let toLoginSystemList = [`"bi"`, `"development"`, `"backend"`]
        let systemCode = sessionStorage.getItem('portal_root_appName')
        let originDomain = sessionStorage.getItem('TENANT_ORIGIN_DOMAIN')
        console.log('退出登录', toLoginSystemList, systemCode, toLoginSystemList.includes(systemCode))
        if (toLoginSystemList.includes(systemCode)) {
          toRoutePath = `${originDomain}/gtm/web/platform/login`
        } else {
          toRoutePath = `${originDomain}/gtm/web${this.$store.state.defaultMenuInfo.defaultPath}`
        }
        this.$rootMethods.TOOLS.clearLocalInfo()
        this.$store.state.rootMicroAppData = null
        this.$rootState.userInfo = {}
        this.$rootState.indexInfo = {
          code: 500,
          msg: '尚未登录，请先登录'
        }
        this.$message.success('退出成功')
        if (this.$route?.query?.loginOut) {
          window.location.href = toRoutePath
        } else {
          if (!window.location.origin.includes(originDomain)) {
            toRoutePath = `${toRoutePath}?loginOut=trade`
          }
          window.location.href = toRoutePath
        }
      }, 500)
    }
  }
}
</script>

<style lang="scss">
$--height--Header: 60px; //头部组件的高度
$--base-color: #fff; //品牌色
$--hover-color: #fff; //悬浮色
$--click-color: #fff; //选中色
$--font-color: #fff;
$heightHeader: 70px;
.headerContainer {
  box-sizing: border-box;
  width: 100%;
  min-width: 1200px;
  height: $--height--Header;
  display: flex;
  align-items: center;
  color: $--font-color;
  background-image: linear-gradient(to right, #0d33fe, #0c6ef7);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  padding: 0 20px;

  .login-btn {
    width: 68px;
    height: 34px;
    line-height: 1;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 17px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #165dff;
      text-align: left;
      font-style: normal;
    }
  }

  .login-btn:hover {
    border: none;
    opacity: 0.8;
    background: rgba(255, 255, 255, 0.9);

    span {
      color: #165dff;
      //font-weight: bold;
      //border: #fff;
    }
  }

  .register-btn {
    font-family: MicrosoftYaHei;
    height: 34px;
    font-size: 14px;
    color: #ffffff;
    line-height: 19px;
    font-style: normal;
    margin-right: 20px;
    line-height: 1;
  }

  .register-btn:hover {
    height: 34px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 1;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 17px;
    border: 1px solid #FFFFFF;
    color: #165DFF;
    margin-right: 0px;
    //margin-right: 10px;
  }

  .container {
    width: 100%;
    display: flex;
    align-items: center;

    .left {
      display: flex;
      align-items: center;

      .logo-box {
        display: flex;
        //height: 44px;
        margin-right: 15px;
        position: relative;
      }

      .platform-name-box {
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 26px;
        text-align: left;
        font-style: normal;
      }
    }

    .midden {
      display: flex;
      flex: 1;
      margin-left: 34px;

      .listRow {
        font-size: 16px;
        padding-left: 20px;
        padding-right: 20px;
        line-height: $--height--Header;
        font-style: normal;
        box-sizing: border-box;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        text-align: left;

        @mixin Active {
          position: relative;
          transition: all 0.3;
          font-weight: bold;
          color: $--base-color;
          cursor: pointer;
          font-style: normal;
          &::after {
            content: '';
            height: 2px;
            width: 64px;
            background: $--base-color;
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
        }
        @mixin Hover {
          transition: all 0.3;
          font-weight: bold;
          color: $--base-color;
          cursor: pointer;
          font-style: normal;
          border-color: transparent;
          background-color: transparent;
          line-height: $--height--Header;
        }

        &.isActive {
          @include Active();
        }

        &:hover {
          @include Hover();
        }
      }
    }

    @mixin flexCenter {
      display: flex;
      align-items: center;
    }

    .right {
      @include flexCenter();
      display: flex;
      align-items: center;
      height: 100%;

      .gtm-user-order-box {
        cursor: pointer;
        position: relative;
        right: 26px;
        color: #fff;
        height: 34px;
        width: 144px;
        font-size: 16px;
        flex-direction: column;
        justify-content: center;
        display: flex;
        align-items: center;
        border-radius: 17px;
        border: 1px solid transparent;

        span {
          display: flex;
          align-items: flex-start;
        }

        .order-text {
          padding-left: 6px;
          padding-top: 1px;
        }
      }

      .gtm-user-order-box:hover {
        color: #165DFF;
        background: rgba(255, 255, 255, 0.9);
        border: 1px solid #FFFFFF;

        i {
          color: #165DFF;
        }
      }

      .gtm-message-box {
        cursor: pointer;
        position: relative;
        right: 20px;
        color: #fff;
        height: 34px;
        width: 124px;
        font-size: 16px;
        flex-direction: column;
        justify-content: center;
        display: flex;
        align-items: center;
        border-radius: 17px;
        border: 1px solid transparent;

        span {
          display: flex;
          align-items: flex-start;
        }

        .order-text {
          padding-left: 6px;
          padding-top: 1px;
        }

        .el-badge {
          display: flex;
          flex-direction: row;
        }
      }

      .gtm-message-box:hover {
        color: #165DFF;
        background: rgba(255, 255, 255, 0.9);
        border: 1px solid #FFFFFF;

        i {
          color: #165DFF;
        }
      }

      .goHomeClass {
        @include flexCenter();
        cursor: pointer;
        margin-right: 60px;

        .btnHome {
          color: #333;
          margin-top: 1px;

          &:hover {
            color: #0082e6;
          }
        }

        img {
          margin-right: 5px;
        }
      }

      .loginClass {
        @include flexCenter();

        &:hover {
          cursor: pointer;

          .user {
            color: $--font-color !important;
          }

          .el-icon-arrow-down {
            transform: rotate(180deg);
            color: $--font-color;
          }
        }

        .user {
          margin-left: 10px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: $--font-color;
        }

        .logo {
          flex-shrink: 0;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          background: url(~assets/images/home/userAvatar.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center center;
        }

        .el-icon-arrow-down:before {
          color: $--font-color;
          font-size: 12px;
        }
      }

      .no-pointer {
        &:hover {
          cursor: unset !important;
        }
      }

      .noLoginClass {
        @include flexCenter();

        .login {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #333333;
          margin-right: 5px;

          &:hover {
            color: $--hover-color;
          }

          &:active {
            color: $--click-color;
          }
        }

        .register {
          width: 86px;
          height: 38px;
          box-sizing: border-box;
          background: $--base-color;
          border-radius: 2px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 19px;
          padding: 8px 15px 12px;

          &:hover {
            background: $--hover-color;
          }

          &:active {
            background: $--click-color;
          }
        }
      }
    }
  }
}

.bg-icon {
  background: url(~assets/images/navIcon.png);
  background-size: 14px 14px;
  color: transparent;
}

.dropdowns {
  box-sizing: border-box;
  width: 200px;
  background: #ffffff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
  0px 6px 16px 0px rgba(0, 0, 0, 0.08),
  0px 3px 6px -4px rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px !important;

  .userInfoName {
    font-family: MicrosoftYaHei, MicrosoftYaHei;
    font-weight: bold;
    font-size: 16px;
    padding-left: 20px;
    color: #333333;
    line-height: 30px;
    height: 40px;
    text-align: left;
    font-style: normal;
  }

  .userInfoID {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #666666;
    margin-top: 10px;
    padding-left: 10px;
  }

  //
  .el-menu-item.is-active {
    background: rgba(208, 223, 255, 0.5) !important;
    border-radius: 8px !important;
    font-family: MicrosoftYaHei, MicrosoftYaHei;
    font-weight: bold;
    color: #165dff;
    text-align: left;
    font-style: normal;
  }

  .el-menu-item {
    background-color: #fff;
    line-height: 40px;
    font-size: 14px;
    border-radius: 8px;
    padding-left: 20px !important;
    // margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .el-menu-item:focus,
  .el-menu-item:hover {
    background: #e4e6e7;
  }

  .el-menu-vertical {
    padding: 0px;

    .submenu {
      > .el-submenu__title:hover {
        background-color: #dfe9f3;
      }

      &.el-submenu.is-active > .el-submenu__title::before {
        content: none;
      }

      &.el-submenu [class*='icon-'],
      .el-submenu [class^='el-icon-'] {
        margin-right: 15px;
      }

      .el-submenu__title {
        padding-left: 12px;
      }

      .el-menu-item.is-active {
        background: rgba(208, 223, 255, 0.5);
        border-radius: 8px;
      }

      .el-menu-item {
        background-color: #fff;
        border-radius: 8px;
        line-height: 40px;
        font-size: 14px;
        padding-left: 15px !important;
        margin-left: 5px !important;
        margin-right: 5px !important;

        &:hover {
          background: #e4e6e7;
        }
      }
    }
  }

  .hr {
    border-bottom: 1px solid #d2d2d2;
    margin-bottom: 20px;
    //margin: 19px 0px 10px 0px;
  }

  .loginOut {
    position: relative;
    top: 10px;
    width: 100%;
    height: 46px;
    border-top: solid 1px #d2d2d2;
    margin-top: 10px;
    display: flex;
    justify-content: left;
    align-items: center;

    &:hover {
      cursor: pointer;
      background: rgba(0, 130, 230, 0.1);
    }

    .login-out-text {
      display: inline-block;
      margin-left: 14px;
      margin-top: 1px
    }
  }

  .text {
    display: block;
    margin-left: 20px;
    font-size: 14px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    color: #333333;
    cursor: pointer;
  }
}

.el-submenu__icon-arrow {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -7px;
  transition: transform 0.3s;
  font-size: 12px !important;
}

//.el-menu {
//  font-size: 14px !important;
//}
</style>
