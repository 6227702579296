import axios from 'axios'
import { DEVELOPMENT_PATH } from '../../base-url'

export default {
  /**
   * 产品版本信息列表
   * @param params
   * @param data
   * @returns {*} $developmentApi.PRODUCT.fetchGetProductInfoVersionList
   */
  fetchGetProductInfoVersionList (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/productInfo/version/list`, data, { params })
      .then(res => res.data)
  },
  /**
   * 保存产品版本信息
   * @param params
   * @param data
   * @returns {*} $developmentApi.PRODUCT.fetchSaveProductInfoVersion
   */
  fetchSaveProductInfoVersion (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/productInfo/version/save`, data, { params })
      .then(res => res.data)
  },
  /**
   * 删除产品版本
   * @param id
   * @returns {*} $developmentApi.PRODUCT.fetchDeleteProductInfoVersion
   */
  fetchDeleteProductInfoVersion (id) {
    return axios.delete(`${DEVELOPMENT_PATH}/productInfo/version/delete/${id}`)
      .then(res => res.data)
  },
  /**
   * 复制版本
   * @param interfaceId
   * @param params
   * @returns {*} $developmentApi.PRODUCT.fetchAddVersionCopy
   */
  fetchAddVersionCopy (id) {
    return axios.post(`${DEVELOPMENT_PATH}/productInfo/version/versionCopy/${id}`)
      .then(res => res.data)
  }
}
