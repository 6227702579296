<template>
  <div class="gtm-area-cascader">
    <el-cascader
      style="width: 100%"
      :placeholder="placeholder"
      size="large"
      filterable
      :clearable="clearable"
      :options="areaOptions"
      v-model="currentAreaCode"
      @change="projectAreaChange"
      :props="{ checkStrictly: true }"
      :disabled="disabled"
      :append-to-body="false"
      @keydown.native.enter.capture.stop.prevent
    >
      <template slot-scope="{ data }">
        <span>{{ data.label }}</span>
        <span style="color: #ccc">{{ data.disabled ? '    暂未开通' : '' }}</span>
      </template>
    </el-cascader>
  </div>
</template>

<script>
import { gtmCityData } from 'web-utils'

export default {
  name: 'GtmAreaCascader',
  components: {},
  mixins: [],
  props: {
    areaCode: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    clearable: {
      type: Boolean,
      default: true
    },
    dataType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {
    currentAreaCode: {
      get () {
        return this.areaCode.split(',')
      },
      set () {
        this.$emit('update:areaCode', this.currentAreaCode)
      }
    },
    areaOptions () {
      return this.dataType === 'full' ? gtmCityData.fullProvinceData : gtmCityData.provinceAndCityData
    }
  },
  watch: {},
  created () {
  },
  mounted () {
  },
  methods: {
    projectAreaChange (value) {
      this.$emit('change', value.join(','))
    }
  }
}
</script>
<style lang="scss">
.gtm-area-cascader {
  width: 100%;
}
</style>
