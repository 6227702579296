<template>
  <div class="login-wrapClass">
    <div class="login-right-wrap full-screen">
      <div class="login-form-wrap full-screen">
        <div class="login-form-top">
          <img src="../../assets/images/login/gtm-logo.png" alt=""/>
          <h2 class="system-title">
            <span>广联达交易数字综合金融服务平台</span>
          </h2>
        </div>
        <div class="login-form-body">
          <el-tabs v-model="activeVal" @tab-click="handleChangeLogin">
            <el-tab-pane label="账号登录" name="account"></el-tab-pane>
          </el-tabs>
          <ul class="login-form-username" v-if="activeVal === 'gtm_center'">
            <div class="gtm_centerClass">
              <div class="login_tipClass">
                <i class="el-icon-warning"></i>
                提示︰企业用户点击下方<b> 「立即登录」</b>系统将跳转到<b>「广联达用户中心」</b>进行登录。
              </div>
              <div class="imgLogo">
                <img width="100%" height="100%" src="../../assets/images/login/gtn_login_center.png" alt=""/>
              </div>
              <div class="bg"></div>
            </div>
            <li class="btn-wrap">
              <p>
                <span
                  class="gtm-btn submit-btn"
                  @click="handleCasLogin"
                  @keydown.enter="onKeyDown"
                  id="submitBtn"
                >立即登录</span>
              </p>
            </li>
          </ul>
          <ul class="login-form-username" v-else>
            <template v-if="activeName === 'account'">
              <li class="input-wrap input-username">
                <el-input
                  class="login-input gtm-input-none input_42"
                  v-model="loginModel.username"
                  name="username"
                  key="username"
                  autocomplete="new-password"
                  placeholder="请输入用户名"
                ></el-input>
              </li>
              <li class="input-wrap input-password">
                <el-input
                  class="login-input gtm-input-none input_42"
                  v-model="loginModel.password"
                  name="password"
                  key="password"
                  placeholder="请输入密码"
                  show-password
                  autocomplete="new-password"
                  @keyup.enter.native="getPublicKey"
                ></el-input>
              </li>
              <li class="input-wrap input-captcha">
                <!-- span class="input-kaptcha">密码</span -->
                <el-input
                  class="login-input gtm-input-none input_42"
                  v-model="loginModel.kaptcha"
                  name="kaptcha"
                  key="kaptcha"
                  placeholder="请输入验证码"
                ></el-input>
                <img :src="verifyCodeUrl" class="verifyCodeUrl" alt="验证码" @click="freshKaptchaImage"/>
              </li>
            </template>
            <li class="btn-wrap">
              <div class="login-error-msg" v-if="loginNameErrorMsg && activeName === 'account'">
                <i class="el-icon-error"></i>{{ loginNameErrorMsg }}
              </div>
              <template v-if="activeName === 'cellPhone'">
                <div class="login-warning-msg" v-if="isHasEffectCode">
                  <i class="el-icon-success"></i>验证码已发送至您的手机，请注意查收！
                </div>
                <div class="login-error-msg" v-else-if="loginPhoneErrorMsg">
                  <i class="el-icon-error"></i>{{ loginPhoneErrorMsg }}
                </div>
              </template>
              <p v-loading="loginLoading">
                <span
                  class="gtm-btn submit-btn"
                  @click="getPublicKey"
                  id="submitBtn"
                >立即登录</span>
              </p>
              <p class="clearfix">
                <span class="gtm-btn submit-btn register-btn" @click="register" id="submitBtn">注册</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { JSEncrypt } from 'jsencrypt'

export default {
  data () {
    return {
      loginNameErrorMsg: '',
      loginPhoneErrorMsg: '',
      // 验证码剩余有效时长
      effectiveDurationTime: 60,
      // 是否已经获取验证码
      isHasEffectCode: false,
      // 标志获取验证码按钮是否已经被点击过
      isCheckMobileClick: false,
      loginModel: {
        // 账号登录
        username: '',
        password: '',
        // 图形验证码
        kaptcha: '',
        // 手机号登录
        captcha: '',
        mobile: ''
      },
      publicKey: '',
      isClick: false,
      // 验证码链接
      verifyCodeUrl: '',
      activeName: 'account',
      hasCaptcha: false,
      activeVal: 'account',
      loginLoading: false,
      keyUpFunc: {}
    }
  },
  mounted () {
    this.getKaptchaImageFn()
    this.keyUpFunc = (event) => {
      if (event.key === 'Enter') {
        // 在这里编写当按下Enter键时想要执行的代码
        this.getPublicKey()
      }
    }
    window.addEventListener('keyup', this.keyUpFunc)
  },
  beforeDestroy () {
    window.removeEventListener('keyup', this.keyUpFunc)
  },
  methods: {
    freshKaptchaImage () {
      // 每次刷新
      this.getKaptchaImageFn()
    },
    getKaptchaImageFn () {
      console.log(111)
      this.verifyCodeUrl = this.$guaranteeApi.USER.fetchUserGenVerifyImage()
    },
    // ...mapActions(['getUserIndexData']),
    handleCasLogin () {
      console.log('handleCasLogin')
    },
    onKeyDown () {
      console.log(11111)
    },
    // 不同登录方式状态切换
    handleChangeLogin (tab) {
      this.activeName = tab.name
    },
    encryptedData (publicKey, data) {
      // 新建JSEncrypt对象
      let encryptor = new JSEncrypt()
      // 设置公钥
      encryptor.setPublicKey(publicKey)
      // 加密数据
      return encryptor.encrypt(data)
    },
    submit () {
      let params = {}
      if (this.activeName === 'account') {
        let { username, password, kaptcha } = this.loginModel
        if (!username) {
          this.loginNameErrorMsg = '用户名不能为空'
          this.isClick = false
          return
        }
        if (!password) {
          this.loginNameErrorMsg = '密码不能为空'
          this.isClick = false
          return
        }
        if (!kaptcha) {
          this.loginNameErrorMsg = '验证码不能为空'
          this.isClick = false
          return
        }
        password = this.encryptedData(this.publicKey, password)
        params = {
          username: username,
          password: password,
          kaptcha
        }
      }
      this.loginLoading = true
      this.$guaranteeApi.COMMON_LOGIN.fetchToLogin({}, params)
        .then(async res => {
          if (res && res.code === 0) {
            // 这个时候还没有挂载子应用，应该挂载子应用，并指定子应用路由
            await this.$rootMethods.APP_CONTEXT.initAppContext()
              .finally(() => {
                this.$store.state.rootMicroAppData = this.$rootState
                // 优化后台登录跳转流程
                const menus = this.$rootState.indexInfo?.menus?.children || []
                const firstMenu = menus.length > 0 ? menus[0] : {}
                let url = ''
                // 有一级菜单地址跳转一级菜单地址；没有的话跳二级
                if (firstMenu?.resUrl) {
                  url = firstMenu.resUrl
                } else if (firstMenu?.children && firstMenu.children.length > 0) {
                  url = firstMenu.children[0].resUrl
                }
                let appName = url.split('/')[0]
                // 存储当前应用名称，跳转对应前端应用
                this.$rootMethods.APP_CONTEXT.setLocalValue('portal_root_appName', `"${appName}"`)
                this.$router.push({
                  path: `/platform/${url}`
                })
              })
          } else {
            if (this.activeName === 'account') {
              this.loginNameErrorMsg = res.msg
            } else {
              this.loginPhoneErrorMsg = res.msg
            }
          }
          this.isClick = false
        })
        .catch(() => {
          this.isClick = false
        })
        .finally(() => {
          this.loginLoading = false
        })
    },
    getPublicKey () {
      if (this.isClick) {
        return
      }
      this.isClick = true
      this.$guaranteeApi.COMMON_LOGIN.fetchGetPublicKey({
        time: new Date() * 1
      })
        .then(res => {
          if (res.code === 0) {
            this.publicKey = res.public_key
            this.submit()
          } else {
            this.$message({
              message: '登录初始化失败',
              type: 'error',
              iconClass: 'el-icon-error',
              single: true
            })
            this.isClick = false
          }
        })
        .catch(() => {
          this.isClick = false
        })
    },
    register () {
      this.$router.push({
        name: 'Register'
      })
    },
    retrieve () {
      this.$router.push({
        name: 'Retrieve'
      })
    }
  }
}
</script>

<style lang="scss">
.login-wrapClass {
  background: url(../../assets/images/login/login-bg.png) center center no-repeat;
  background-size: cover;
  // min-height: 645px;
  height: 100%;

  .login-right-wrap {
    // width: 26.7rem;
    background: rgba($color: #000000, $alpha: 0.75);
    float: right;

    .login-form-wrap {
      color: #fff;

      .login-form-top {
        text-align: center;
        // padding-bottom: 50px;
        img {
          // margin-top: 7.62rem;
          width: 100px;
          height: 100px;
          border-radius: 44px;
          display: inline-block;
        }

        h2.system-title {
          font-size: 1.1rem;
          font-weight: 500;
          color: #ffffff;
          line-height: 30px;
          margin: 21px 0 0 0;

          span {
            display: inline-block;
            width: 10.286rem;
          }
        }
      }

      .login-form-body {
        /*   .login-title-wrap{
                text-align: center;
                span {
                    display: inline-block;
                    cursor: pointer;
                    width: 170px;
                    height: 44px;
                    font-size: 16px;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 44px;
                    border-radius: 2px;
                    letter-spacing: 2px;
                    box-sizing: border-box;
                    border: 1px solid rgba(255, 255, 255, 0.2);
                    opacity: 0.6;
                    &.is-active {
                      border: 1px solid rgba(255, 255, 255, 0.8);
                      opacity: 1;
                    }
                }
            } */
        .el-tabs__nav-scroll {
          height: 40px;
        }

        .el-tabs__item {
          background: transparent;

          &:not(is-active) {
            color: #fff;
            opacity: 0.75;
          }

          &.is-active {
            color: #ffffff;
            opacity: 1;
          }
        }

        .el-tabs__active-bar {
          box-sizing: content-box;
          bottom: -6px;
          background-color: #fff;
        }

        .login-form-username {
          // padding-top: 50px;
          position: relative;

          .gtm_centerClass {
            width: 402px;
            height: 170px;
            box-sizing: border-box;
            border-radius: 2px;
            margin-bottom: 43px;
            position: relative;
            padding: 20px 27px 11px;

            .bg {
              width: 100%;
              height: 100%;
              background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
              opacity: 0.2;
              // z-index:10;
              position: absolute;
              top: 0;
              left: 0;
            }

            .login_tipClass {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #ff8c00;

              i {
                margin-right: 4px;
              }
            }

            .imgLogo {
              width: 348px;
              height: 79px;
              margin-top: 18px;
              // background-size: 100% 100%;
              // background: url('../../assets/images/login/gtn_login_center.png');
            }
          }

          li {
            position: relative;
            margin-bottom: 10px;

            &.input-wrap {
              border-bottom: 1px solid rgba(255, 255, 255, 0.6);
            }

            &.input-username::before {
              content: '';
              background: url(../../assets/images/login/gtm-login-username@2x.png) center center no-repeat;
              background-size: 100% 100%;
              width: 20px;
              height: 20px;
              bottom: 11px;
              position: absolute;
              z-index: 1;
            }

            &.input-password::before {
              content: '';
              background: url(../../assets/images/login/gtm-login-password@2x.png) center center no-repeat;
              background-size: 100% 100%;
              width: 20px;
              height: 20px;
              bottom: 11px;
              position: absolute;
              z-index: 1;
            }

            &.input-captcha {
              input {
                padding-right: 110px;
              }

              &::before {
                content: '';
                background: url(../../assets/images/login/gtm-login-captcha@2x.png) center center no-repeat;
                background-size: 100% 100%;
                width: 20px;
                height: 20px;
                bottom: 11px;
                position: absolute;
                z-index: 1;
              }

              .verifyCodeUrl,
              .gtm-tel-captcha {
                position: absolute;
                right: 0px;
                top: 2px;
                width: 100px;
                height: 34px;
                border-radius: 2px;
              }

              .gtm-tel-captcha {
                padding: 0;
                background: rgba(255, 255, 255, 0.2);
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
                border: none;

                &:hover,
                &:focus {
                  background: rgba(255, 255, 255, 0.1);
                }
              }
            }

            &.input-mobile::before {
              content: '';
              background: url(../../assets/images/login/gtm-login-mobile@2x.png) center center no-repeat;
              background-size: 100% 100%;
              width: 20px;
              height: 20px;
              bottom: 11px;
              position: absolute;
              z-index: 1;
            }

            &.btn-wrap {
              font-size: 16px;
              // padding-top: 50px;
              p + p {
                // margin-top: 20px;
              }

              .submit-btn {
                &.gtm-btn {
                  cursor: pointer;
                  display: inline-block;
                }

                width: 100%;
                height: 50px;
                text-align: center;
                line-height: 50px;
                // background: linear-gradient(168deg, #318AE5 0%, #60C1F8 100%);
                background: linear-gradient(90deg, #40a9ff 0%, #257ae7 100%);
                border-radius: 2px;
                font-weight: 400;
                color: #ffffff;

                &:hover,
                &:active {
                  background: linear-gradient(90deg, #40a9ff 0%, #257ae7 100%);
                  opacity: 0.7;
                }
              }

              .register-btn {
                background: #fff;
                color: #555;

                &:hover,
                &:active {
                  background: #fff;
                  opacity: 0.7;
                }
              }

              .gtm-default-btn {
                opacity: 0.8;

                &:hover,
                &:active {
                  opacity: 1;
                }
              }
            }

            .input-label {
              width: 65px;
              height: 42px;
              line-height: 42px;
              display: inline-block;
              font-size: 16px;
              font-weight: 500;
              text-align: left;
            }

            .login-input {
              input {
                border: none;
                background: transparent;
                height: 42px;
                line-height: 42px;

                font-size: 16px;
                font-weight: 500;
                color: #ffffff;
                text-indent: 1.7rem;

                &::placeholder {
                  color: rgba(255, 255, 255, 0.6);
                  font-size: 16px;
                }

                &::-moz-placeholder {
                  color: rgba(255, 255, 255, 0.6);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}

.login-error-msg {
  position: absolute;
  top: 5px;
  height: 34px;
  line-height: 34px;
  background: rgba(255, 75, 83, 0.2);
  border-radius: 2px;
  border: 1px solid #ff4b53;
  font-size: 16px;
  font-weight: 400;
  color: #ff4b53;
  width: 100%;

  .el-icon-error {
    margin: 0 10px 0 20px;
    font-size: 18px;
  }
}

.login-warning-msg {
  position: absolute;
  top: 5px;
  height: 34px;
  line-height: 34px;
  background: rgba(86, 181, 2, 0.2);
  border-radius: 2px;
  border: 1px solid rgba(86, 181, 2, 0.3);
  font-size: 16px;
  font-weight: 400;
  color: #56b502;
  width: 100%;

  .el-icon-success {
    margin: 0 10px 0 20px;
    font-size: 18px;
  }
}
</style>
