import axios from 'axios'
import { SYS_APP_PATH } from '../../base-url'

export default {
  /**
   * 获取首页模块条目数据
   * @param params
   * @returns {any} $sysappApi.SETTING.fetchGetHomeItem
   */
  fetchGetHomeItem (params) {
    return axios.get(`${SYS_APP_PATH}/setting/home/item/find`, { params })
      .then(res => res.data)
  },
  /**
   * 保存首页模块条目数据
   * @param params
   * @param data
   * @returns {any} $sysappApi.SETTING.fetchSaveHomeItem
   */
  fetchSaveHomeItem (params, data) {
    return axios.post(`${SYS_APP_PATH}/setting/home/item/save`, data, { params })
      .then(res => res.data)
  },
  /**
   * 删除首页模块条目数据
   * @param params
   * @param data
   * @returns {any} $sysappApi.SETTING.fetchDeleteHomeItem
   */
  fetchDeleteHomeItem (params, data) {
    return axios.post(`${SYS_APP_PATH}/setting/home/item/delete`, data, { params })
      .then(res => res.data)
  }
}
