import INSTITUTION from './institution/index'
import BUSINESS from './business/index'
import PRODUCT from './product/index'
import INTERFACE from './interface/index'
import GO_ONLINE from './go-online/index'
import OPEN_PLATFORM from './open-platform/index'

export default {
  INSTITUTION,
  BUSINESS,
  INTERFACE,
  PRODUCT,
  GO_ONLINE,
  OPEN_PLATFORM
}
