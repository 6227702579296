import axios from 'axios'
import { SYS_APP_PATH } from '../../base-url'

export default {
  /**
   * 查询租户全局配置
   * @param params
   * @returns {*} $sysappApi.SETTING.fetchGetSettingGlobal
   */
  fetchGetSettingGlobal (params) {
    return axios.get(`${SYS_APP_PATH}/setting/global/find`, { params })
      .then(res => res.data)
  },
  /**
   * 保存租户全局配置
   * @param params
   * @returns {*} $sysappApi.SETTING.fetchSaveSettingGlobal
   */
  fetchSaveSettingGlobal (params, data) {
    return axios.post(`${SYS_APP_PATH}/setting/global/save`, data, { params })
      .then(res => res.data)
  }
}
