import axios from 'axios'
import { SYS_APP_PATH } from '../../base-url'

export default {
  /**
   * 租户列表
   * @param params
   * @returns {*} $sysappApi.TENANT.fetchGetTenantList
   */
  fetchGetTenantList (params) {
    return axios.get(`${SYS_APP_PATH}/tenant/manager/list`, { params })
      .then(res => res.data)
  },
  /**
   * 创建租户
   * @param params
   * @returns {*} $sysappApi.TENANT.fetchAddTenant
   */
  fetchAddTenant (params, data) {
    return axios.post(`${SYS_APP_PATH}/tenant/manager/create`, data, { params })
      .then(res => res.data)
  },
  /**
   * 更新租户
   * @param params
   * @returns {*} $sysappApi.TENANT.fetchUpdateTenant
   */
  fetchUpdateTenant (params, data) {
    return axios.post(`${SYS_APP_PATH}/tenant/manager/update`, data, { params })
      .then(res => res.data)
  },
  /**
   * 删除租户
   * @param params
   * @returns {*} $sysappApi.TENANT.fetchDeleteTenant
   */
  fetchDeleteTenant (params, data) {
    return axios.post(`${SYS_APP_PATH}/tenant/manager/delete`, data, { params })
      .then(res => res.data)
  },
  /**
   * 查询租户
   * @param params
   * @returns {*} $sysappApi.TENANT.fetchGetTenantFind
   */
  fetchGetTenantFind (params) {
    return axios.get(`${SYS_APP_PATH}/tenant/manager/find`, { params })
      .then(res => res.data)
  }
}
